import React from "react";
import { Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { profileStore } from "../../State/ProfileStore";
import "./SubscriptionConfirmation.css";
import { routes } from "../../Util/routes";

const CustomButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	backgroundColor: theme.palette.info.main,
	"&:hover": {
		backgroundColor: "#23374C",
	},
}));

const SubscriptionConfirmation = () => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		profileStore.setCurrentTabIndex(0);
		navigate(routes.am.profile);
	};

	return (
		<div className="component-wrapper">
			<div className="subscription-confirmation">
				<Typography
					variant="h4"
					gutterBottom
					sx={{ mb: 5, fontWeight: "bold", color: "primary.contrast" }}
				>
					Subscription Successfully Updated!
				</Typography>
				<Typography
					variant="body1"
					gutterBottom
					sx={{ mb: 5, color: "primary.contrast" }}
				>
					We're excited to have you on board. Your subscription has been successfully
					activated.
					<br />
					The new features will be unlocked for you shortly. Stay tuned for an enhanced
					experience!
				</Typography>
				<CustomButton variant="contained" onClick={handleButtonClick}>
					Return to Assistant Manager
				</CustomButton>
			</div>
		</div>
	);
};

export default SubscriptionConfirmation;
