import React from "react";
import "../Datapage/Datapage.css";
import RadarChart from "./RadarChart";

const PassingsChart = ({ firstClubData, secondClubData }) => {
	const data1 = {
		team: [
			firstClubData?.P_PassesSuccessful,
			firstClubData?.P_PossessionPercentage,
			firstClubData?.P_ForwardPassesSuccessful,
			firstClubData?.P_BackPassesSuccessful,
			firstClubData?.P_LateralPassesSuccessful,
			firstClubData?.P_PassToFinalThirdSuccessful,
			firstClubData?.P_MatchTempo,
			firstClubData?.P_AvgPassesPerPossession,
		],
		opponents: [
			secondClubData?.P_PassesSuccessful,
			secondClubData?.P_PossessionPercentage,
			secondClubData?.P_ForwardPassesSuccessful,
			secondClubData?.P_BackPassesSuccessful,
			secondClubData?.P_LateralPassesSuccessful,
			secondClubData?.P_PassToFinalThirdSuccessful,
			secondClubData?.P_MatchTempo,
			secondClubData?.P_AvgPassesPerPossession,
		],
		leagueAvg: [0, 0, 0, 0, 0, 0, 0, 0],
	};
	const labels1 = [
		"Successful passes",
		"Possession %",
		"Accurate forward passes",
		"Accurate back passes",
		"Accurate lateral passes",
		"Accurate passes to final third",
		"Match tempo",
		"Average passes per possession",
	];

	const data2 = {
		team: [
			firstClubData?.P_LossesPerPossession,
			firstClubData?.P_CrossPerPass,
			firstClubData?.P_LongPassPerPass,
		],
		opponents: [
			secondClubData?.P_LossesPerPossession,
			secondClubData?.P_CrossPerPass,
			secondClubData?.P_LongPassPerPass,
		],
		leagueAvg: [0, 0, 0],
	};

	const labels2 = ["Losses per possession", "Crosses per pass", "Long pass %"];

	return (
		<div
			className="chart-section"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<div style={{ flex: "1 1 35%" }}>
				<h2>High values indicate positive performance</h2>
				<RadarChart
					teamName={firstClubData?.ClubGroup}
					secondClubName={secondClubData?.ClubGroup}
					data={data1}
					labels={labels1}
				/>
			</div>

			<div style={{ flex: "1 1 35%" }}>
				<h2>Low values indicate positive performance</h2>
				<RadarChart
					teamName={firstClubData?.ClubGroup}
					secondClubName={secondClubData?.ClubGroup}
					data={data2}
					labels={labels2}
				/>
			</div>
		</div>
	);
};

export default PassingsChart;
