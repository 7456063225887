import React, { useEffect, useState } from "react";
import { CheckCircleOutlined, HighlightOff, ShoppingCart } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	styled,
	Typography,
} from "@mui/material";
import { profileStore } from "../State/ProfileStore";
import theme from "../Style/theme";
import { auth0Service } from "../Util/Auth0Client";
import { capitalizeString } from "../Util/textFormatting";
import CancelSubscription from "./CancelSubscription";
import SubscriptionPlans from "./SubscriptionPlans";
import {
	chargebeeCheckout,
	getCustomerSubscription,
	openChargebeePortal,
	parseSubscriptionItems,
} from "./utilFunctions";

const CustomButton = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	"&:hover": {
		backgroundColor: theme.palette.info.dark,
		color: theme.palette.common.white,
	},
}));

const MySubscription = () => {
	const [customerFeatures, setCustomerFeatures] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [subscriptionData, setSubscriptionData] = useState([]);
	const [currentPlanName, setCurrentPlanName] = useState([]);

	const redirectToPlans = () => {
		profileStore.setCurrentTabIndex(1);
	};

	const reactivateSubscription = async (subscriptionId) => {
		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			await fetch(
				`/api/chargebee/customer/subscription?subscriptionId=${subscriptionId}`,
				{
					method: "PATCH",
					headers: header,
					credentials: "include",
				}
			);
		} catch (error) {
			console.log("Something went wrong", error);
		} finally {
			getData();
		}
	};

	const customerHasFeatures = (featureData, customerSubscriptionItems) => {
		return featureData.map((featureObj) => {
			const feature = featureObj.Feature;
			const entitlements = featureObj.Entitlement;

			let customerHasFeature = false;
			let customerFeatureLevels = [];
			let isAddon = false;
			let entity_Id;

			entitlements.forEach((entitlement) => {
				const entityId = entitlement.entity_id;
				const value = entitlement.value;

				if (entitlement.entity_type === "addon") {
					isAddon = true;
					entity_Id = entityId;
				}

				if (customerSubscriptionItems.has(entityId)) {
					customerHasFeature = true;

					if (feature.levels && feature.levels.length > 0) {
						const levelObj = feature.levels.find((lvl) => lvl.value === value);
						if (levelObj) {
							customerFeatureLevels.push(levelObj.level);
						}
					}
				}
			});

			let highestLevel = null;
			if (customerFeatureLevels.length > 0) {
				highestLevel = Math.max(...customerFeatureLevels);
			}

			let highestLevelName = null;
			if (highestLevel !== null) {
				const levelObj = feature.levels.find((lvl) => lvl.level === highestLevel);
				if (levelObj) {
					highestLevelName = levelObj.name;
				}
			}

			return {
				featureId: feature.id,
				featureName: feature.name,
				hasFeature: customerHasFeature,
				highestLevel,
				isAddon,
				entity_Id,
				highestLevelName,
				levels: feature.levels,
				featureType: feature.type,
			};
		});
	};

	const getFeatures = async () => {
		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch("/api/chargebee/feature", {
				method: "GET",
				headers: header,
				credentials: "include",
			});
			const data = await response.json();
			return data;
		} catch (error) {
			console.log("Something went wrong", error);
		}
	};

	const getData = async () => {
		try {
			const featureData = await getFeatures();
			const customerSubscription = await getCustomerSubscription();
			if (!customerSubscription) return;

			const customerSubscriptionItems = parseSubscriptionItems(
				customerSubscription.subscription_items
			);

			const planItem = customerSubscription.subscription_items.find(
				(item) => item.item_type === "plan"
			);

			let [head] = parseSubscriptionItems([planItem]);
			const parts = head.split("-").map(capitalizeString);
			const features = customerHasFeatures(featureData, customerSubscriptionItems);

			setCurrentPlanName(parts.join(" "));
			setSubscriptionData(customerSubscription);
			setCustomerFeatures(features);
		} catch (error) {
			console.log("Something went wrong", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const includedFeatures = customerFeatures.filter((feature) => feature.hasFeature);
	const missingFeatures = customerFeatures.filter((feature) => !feature.hasFeature);

	return isLoading ? (
		<Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
			<CircularProgress />
		</Box>
	) : subscriptionData?.id ? (
		<Box>
			<Card
				sx={{
					boxShadow: 5,
					borderRadius: 2,
					backgroundColor: "background.light",
					height: "max-content",
				}}
			>
				<CardHeader
					title="My subscription"
					titleTypographyProps={{ align: "center", variant: "h3" }}
					sx={{
						paddingBottom: 0,
						paddingTop: 2,
						position: "relative",
					}}
					action={
						<>
							{subscriptionData?.status === "cancelled" ||
								(subscriptionData?.status === "non_renewing" && (
									<CustomButton
										variant="outlined"
										onClick={() => reactivateSubscription(subscriptionData.id)}
										sx={{
											position: "absolute",
											top: "38%",
											right: 315,
											transform: "translateY(-50%)",
										}}
									>
										Reactivate
									</CustomButton>
								))}
							<CustomButton
								variant="outlined"
								onClick={() => redirectToPlans()}
								sx={{
									position: "absolute",
									top: "38%",
									right: 145,
									transform: "translateY(-50%)",
								}}
							>
								Compare plans
							</CustomButton>
							<CustomButton
								variant="contained"
								onClick={() => openChargebeePortal()}
								sx={{
									position: "absolute",
									top: "38%",
									right: 30,
									transform: "translateY(-50%)",
								}}
							>
								Upgrade
							</CustomButton>
						</>
					}
				/>
				<CardContent>
					<Divider sx={{ mb: 2 }} />
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
						}}
					>
						<Box sx={{ flex: 1, px: 2 }}>
							<Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
								{currentPlanName}
							</Typography>
							<Divider sx={{ mb: 1 }} />
							<List dense>
								{includedFeatures.map((feature) => (
									<ListItem key={feature.featureId}>
										<ListItemIcon>
											<CheckCircleOutlined
												style={{ color: theme.palette.success.main }}
											/>
										</ListItemIcon>
										<ListItemText>
											{feature.featureName}
											{feature.highestLevelName && `: ${feature.highestLevelName}`}
										</ListItemText>
									</ListItem>
								))}
							</List>
						</Box>
						<Divider
							orientation="vertical"
							flexItem
							sx={{ display: { xs: "none", md: "block" } }}
						/>
						<Box sx={{ flex: 1, px: 2 }}>
							<Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
								Missing Features
							</Typography>
							<Divider sx={{ mb: 1 }} />
							<List dense sx={{ alignItems: "start" }}>
								{missingFeatures.map((feature) => (
									<ListItem key={feature.featureId}>
										<ListItemIcon>
											<HighlightOff style={{ color: theme.palette.error.main }} />
										</ListItemIcon>
										<ListItemText>{feature.featureName}</ListItemText>

										{feature.isAddon && (
											<CustomButton
												endIcon={<ShoppingCart />}
												variant="text"
												onClick={() =>
													chargebeeCheckout(subscriptionData.id, feature.entity_Id)
												}
											>
												Add
											</CustomButton>
										)}
									</ListItem>
								))}
							</List>
						</Box>
					</Box>
				</CardContent>
			</Card>
			<CancelSubscription
				userSubscription={subscriptionData}
				apiEndpoint={`/api/chargebee/customer/cancelsubscription?subscriptionId=${subscriptionData.id}`}
				buttonText="subscription"
				httpMethod="POST"
			/>
		</Box>
	) : (
		<SubscriptionPlans />
	);
};

export default MySubscription;
