import horizontalLight from "../Logos/GoalunitLogo_BrightHorisontal.svg";
import verticalLight from "../Logos/GoalunitLogo_BrightVertical.svg";
import horizontalDark from "../Logos/GoalunitLogo_DarkHorisontal.svg";
import verticalDark from "../Logos/GoalunitLogo_DarkVertical.svg";

const waterMarkPlugin = {
	id: "watermark",
	defaults: {
		xAlign: "left", // left, right, center
		yAlign: "bottom", // top, bottom, center
		logoStyle: "horizontal", // horizontal | vertical
		palette: "dark", // dark | light
	},
	afterDatasetsDraw: (chart, _, opts) => {
		const img = new Image();

		const {
			ctx,
			chartArea: { top, left, bottom, width, height },
		} = chart;

		ctx.save();
		ctx.globalAlpha = 0.1;

		let imgWidth;
		let imgHeight;

		if (opts.logoStyle === "horizontal") {
			switch (opts.palette) {
				case "light":
					img.src = horizontalLight;
					break;
				default: // dark
					img.src = horizontalDark;
					break;
			}

			imgWidth = Math.max(Math.min(0.23 * width, 300), 100); // max 300px min 100px
			imgHeight = imgWidth * 0.215; // horizontal img aspect ratio
		} else {
			switch (opts.palette) {
				case "light":
					img.src = verticalLight;
					break;
				default: // dark
					img.src = verticalDark;
					break;
			}

			imgWidth = 0.3 * width;
			imgHeight = 0.3 * width;
		}

		let x;
		let y;
		const distanceFromAxis = Math.min(15, width * 0.03); // 3% of chart width, but max 15px

		switch (opts.yAlign) {
			case "top":
				y = top + distanceFromAxis;
				break;
			case "center":
				y = top + height / 2 - imgHeight / 2;
				break;
			default: // bottom
				y = bottom - distanceFromAxis - imgHeight;
				break;
		}

		switch (opts.xAlign) {
			case "right":
				x = left + width - distanceFromAxis - imgWidth;
				break;
			case "center":
				x = left + width / 2 - imgWidth / 2;
				break;
			default: // left
				x = left + distanceFromAxis;
				break;
		}

		ctx.drawImage(img, x, y, imgWidth, imgHeight);
		ctx.restore();
	},
};

export default waterMarkPlugin;
