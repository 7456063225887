import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import "./FloatingButton.css";

const FloatingButton = ({
	onClickAction,
	isVisible: initialVisibility,
	iconName: IconComponent,
	toolTipText,
}) => {
	const [isButtonVisible, setIsButtonVisible] = useState(initialVisibility);

	const toggleVisibility = () => {
		if (window.pageYOffset > 300 && !initialVisibility) {
			setIsButtonVisible(true);
		} else {
			setIsButtonVisible(initialVisibility);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", toggleVisibility);
		return () => {
			window.removeEventListener("scroll", toggleVisibility);
		};
	}, []);

	return (
		<div className="floating-button">
			{isButtonVisible && (
				<Box onClick={onClickAction} className="floating-button-icon-style">
					<Tooltip title={toolTipText}>
						<IconComponent />
					</Tooltip>
				</Box>
			)}
		</div>
	);
};

export default FloatingButton;
