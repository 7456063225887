import React, { useEffect, useState } from "react";
import { CircularProgress, Container, Typography } from "@mui/material";
import { squad } from "./squad.js";
import SquadContainer from "./SquadContainer.jsx";

const SquadPage = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = () => {
		squad.fetchData().then((data) => {
			setData(data);
			setIsLoading(false);
		});
	};

	const dataIsEmpty = !data || data.length === 0;
	return (
		<Container maxWidth="xxl" sx={{ pt: 3 }}>
			{!isLoading ? (
				dataIsEmpty ? (
					<Typography sx={{ textAlign: "center", fontWeight: 700 }}>
						No squad found
					</Typography>
				) : (
					<SquadContainer data={data} onEditSubmit={fetchData} />
				)
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			)}
			{squad.error && <div className="error-message">{squad.error}</div>}
		</Container>
	);
};

export default SquadPage;
