export const routes = {
	start: {
		base: "/",
		home: "home",
		am: "am",
		services: "services",
		about: "about",
		contact: "contact",
	},
	am: {
		base: "/assistant-manager",
		club: {
			base: "/assistant-manager/club",
			performance: "/assistant-manager/club/performance",
			squad: "/assistant-manager/club/squad",
			matchReport: {
				base: "/assistant-manager/club/match-report",
				pre: "/assistant-manager/club/match-report/pre",
				post: "/assistant-manager/club/match-report/post",
			},
		},
		clubDeprecated: "/assistant-manager/cluboverview",
		squadDeprecated: "/assistant-manager/squadpage",
		profile: "/assistant-manager/profile",
		termsAndConditions: "/assistant-manager/terms-and-conditions",
		subscriptionConfirmation: "/subscriptionConfirmation",
		logOut: "/logout",
	},
};
