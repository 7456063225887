import { auth0Service } from "../Util/Auth0Client";

export default class Squad {
	data = [];
	isLoading = true;
	error = null;

	fetchData = async () => {
		this.setLoading(true);
		this.setError(null);

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch("/api/squadpage", {
				headers: header,
				credentials: "include",
			});

			const squadData = await response.json();
			this.setData(squadData);
			return this.data;
		} catch (error) {
			this.setError(error.message);
		} finally {
			this.setLoading(false);
		}
	};

	setData(data) {
		this.data = data;
	}

	updatePlayer = async (
		playerId,
		clubId,
		firstName,
		lastName,
		dateOfBirth,
		birthPlaceAreaId,
		passPortAreaId,
		foot,
		height,
		weight,
		contractExpiration,
		positionId,
		academyClubIds
	) => {
		try {
			const PositionId = parseInt(positionId, 10);
			const PlayerId = parseInt(playerId, 10);
			const ClubId = parseInt(clubId, 10);
			const BirthPlaceAreaId = parseInt(birthPlaceAreaId, 10);
			const PassPortAreaId = parseInt(passPortAreaId, 10);
			const Height = parseInt(height);
			const Weight = parseInt(weight);

			const token = await auth0Service.getToken();

			const header = {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			};

			const response = await fetch("/api/updateplayer", {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					PlayerId,
					ClubId,
					FirstName: firstName,
					LastName: lastName,
					DateOfBirth: dateOfBirth,
					BirthPlaceAreaId,
					PassPortAreaId,
					Foot: foot,
					Height,
					Weight,
					ContractExpiration: contractExpiration,
					PositionId,
					AcademyClubIds: academyClubIds,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update player");
			}

			this.setLoading(false);
		} catch (error) {
			this.setError(error.message);
			throw error;
		}
	};

	reportPlayerError = async (
		playerId,
		clubId,
		firstName,
		lastName,
		dateOfBirth,
		birthPlaceAreaId,
		passPortAreaId,
		foot,
		height,
		weight,
		contractExpiration,
		positionId,
		academyClubIds
	) => {
		try {
			const PositionId = parseInt(positionId, 10);
			const PlayerId = parseInt(playerId, 10);
			const ClubId = parseInt(clubId, 10);
			const BirthPlaceAreaId = parseInt(birthPlaceAreaId, 10);
			const PassPortAreaId = parseInt(passPortAreaId, 10);
			const Height = parseInt(height);
			const Weight = parseInt(weight);

			const token = await auth0Service.getToken();
			const header = {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			};
			const response = await fetch(`/api/errorreports`, {
				method: "POST",
				headers: header,
				body: JSON.stringify({
					PlayerId,
					ClubId,
					FirstName: firstName,
					LastName: lastName,
					DateOfBirth: dateOfBirth,
					BirthPlaceAreaId,
					PassPortAreaId,
					Foot: foot,
					Height,
					Weight,
					ContractExpiration: contractExpiration,
					PositionId,
					AcademyClubIds: academyClubIds,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to create a report");
			}
			this.setLoading(false);
		} catch (error) {
			this.setError(error.message);
			throw error;
		}
	};

	setLoading(loading) {
		this.isLoading = loading;
	}

	setError(error) {
		this.error = error;
	}
}

export const squad = new Squad();
