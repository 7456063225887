import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTable from "../Components/Table";
import "../Datapage/Datapage.css";

const DoubleChart = ({ data }) => {
	const [tableDataxG, setTableDataxG] = useState([]);
	const [tableDataxA, setTableDataxA] = useState([]);
	const [tableColumnsxG] = useState([
		{ field: "player", headerName: "Player" },
		{ field: "position", headerName: "Position", customStyle: { textAlign: "center" } },
		{ field: "xGPer90", headerName: "xG per 90", customStyle: { textAlign: "center" } },
		{
			field: "dribblesPer90",
			headerName: "Successful dribbles per 90",
			customStyle: { textAlign: "center" },
		},
	]);

	const [tableColumnsxA] = useState([
		{ field: "player", headerName: "Player" },
		{ field: "position", headerName: "Position", customStyle: { textAlign: "center" } },
		{ field: "xAPer90", headerName: "xA per 90", customStyle: { textAlign: "center" } },
		{
			field: "progPassesPer90",
			headerName: "Progressive passes per 90",
			customStyle: { textAlign: "center" },
		},
	]);

	useEffect(() => {
		if (data) {
			const topPlayersxG = data
				.sort((a, b) => b.NonPenxGPer90 - a.NonPenxGPer90)
				.slice(0, 5)
				.map((player) => ({
					player: `${player.FirstName} ${player.LastName}`,
					position: player.PositionShortName,
					xGPer90: player.NonPenxGPer90?.toFixed(2),
					dribblesPer90: player.SuccessfulDribblesPer90?.toFixed(2),
				}));

			// Set the xG table data
			setTableDataxG(topPlayersxG);

			const topPlayersxA = data
				.sort((a, b) => b.XAPer90 - a.XAPer90)
				.slice(0, 5)
				.map((player) => ({
					player: `${player.FirstName} ${player.LastName}`,
					position: player.PositionShortName,
					xAPer90: player.XAPer90?.toFixed(2),
					progPassesPer90: player.ProgressivePassesPer90?.toFixed(2),
				}));

			// Set the xA table data
			setTableDataxA(topPlayersxA);
		}
	}, [data]);

	return (
		<div
			className="chart-section"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			{!tableDataxG || !tableDataxA ? (
				<CircularProgress />
			) : (
				<>
					<div style={{ flex: "1 1 35%", margin: "10px" }}>
						<CustomTable tableData={tableDataxG} tableColumns={tableColumnsxG} />
					</div>
					<div style={{ flex: "1 1 35%", margin: "10px" }}>
						<CustomTable tableData={tableDataxA} tableColumns={tableColumnsxA} />
					</div>
				</>
			)}
		</div>
	);
};

export default DoubleChart;
