export const hexToRgba = (hex, opacity) => {
	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, "");

	// Parse the hex string
	let r, g, b;
	if (hex.length === 3) {
		// If it's a shorthand hex code (e.g., #fff), expand it
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		// If it's a full hex code (e.g., #ffffff)
		r = parseInt(hex.slice(0, 2), 16);
		g = parseInt(hex.slice(2, 4), 16);
		b = parseInt(hex.slice(4, 6), 16);
	} else {
		throw new Error("Invalid hex color format");
	}

	// Ensure opacity is between 0 and 1
	opacity = Math.min(1, Math.max(0, opacity));

	// Return the RGBA string
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
