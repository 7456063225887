import React, { useMemo } from "react";
import "../Datapage/Datapage.css";
import CustomTable from "../Components/Table";
import RadarChart from "./RadarChart";

const DefenceChart = ({ firstClubData, secondClubData, tableData }) => {
	const radarChartData = {
		team: [
			firstClubData?.P_RecoveriesHigh,
			firstClubData?.P_RecoveriesMedium,
			firstClubData?.P_DuelsPercent,
		],

		opponents: [
			secondClubData?.P_RecoveriesHigh,
			secondClubData?.P_RecoveriesMedium,
			secondClubData?.P_DuelsPercent,
		],
		leagueAvg: [0, 0, 0],
	};

	const radarChartLabels = ["Recoveries high", "Recoveries med", "Duels won %"];

	const tableColumns = useMemo(() => {
		return [
			{ field: "team", headerName: "Team" },
			{ field: "fouls", headerName: "Fouls", customStyle: { textAlign: "center" } },
			{
				field: "yellowCards",
				headerName: "Yellow Cards",
				customStyle: { textAlign: "center" },
			},
			{
				field: "redCards",
				headerName: "Red Cards",
				customStyle: { textAlign: "center" },
			},
		];
	}, []);

	return (
		<div
			className="chart-section"
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				height: "100%",
			}}
		>
			<div>
				<h2>High values indicate positive performance</h2>
				<RadarChart
					teamName={firstClubData?.ClubGroup}
					secondClubName={secondClubData?.ClubGroup}
					data={radarChartData}
					labels={radarChartLabels}
				/>
			</div>

			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<h2>Low values indicate positive performance</h2>

				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<CustomTable tableData={tableData} tableColumns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default DefenceChart;
