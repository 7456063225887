import React from "react";
import { Autocomplete, Avatar, Chip, CircularProgress, TextField } from "@mui/material";
import { color } from "../../Style/theme";

const groupHeaderStyle = {
	position: "sticky",
	top: -1,
	padding: "4px 10px",
	color: color.primary.contrast,
	backgroundColor: color.primary.main,
	zIndex: 10,
	fontSize: "16px",
};

const groupItemsListStyle = {
	padding: 0,
};

/**
 * An auto complete select component for clubs
 * Grouped by Division
 * @param {string} label The label for the field
 * @param {boolean} multiple Enable multiselect
 * @param {number[] | number} value Selected club ids, must be array if multiple = true.
 * @param {(Club) => {}} onValueChange A function that gets passed the selected Club(s) on change
 * @param {Club[]} options A list of Clubs to select from
 * @param {boolean} loadingOptions loading state of the options fetch
 *
 * In addition, you can pass any other prop that a MUI Autocomplete can receive
 * if you want to override and/or add configurations
 */
const ClubSelect = ({
	label,
	multiple = false,
	value,
	onValueChange,
	options,
	loadingOptions,
	...props
}) => {
	const normalizeString = (str) =>
		str
			.normalize("NFD")
			.toLowerCase()
			.replace(/[\u0300-\u036f]/g, "") // Remove diacritics (like accents)
			.replace(/ð/g, "d") // Map Icelandic "ð" to "d"
			.replace(/ø/g, "o") // Map Danish "ø" to "o"
			.replace(/æ/g, "ae") // Map Danish "æ" to "ae"
			.replace(/ł/g, "l") // Map Polish "ł" to "l"
			.replace(/ß/g, "ss"); // Map German "ß" to "ss"

	const filterOptions = (options, state) => {
		const inputValue = normalizeString(state.inputValue);
		return options.filter(
			(option) =>
				normalizeString(option.ClubName).includes(inputValue) ||
				normalizeString(option.DivisionName).includes(inputValue) ||
				normalizeString(option.OfficialName).includes(inputValue)
		);
	};

	const selectedValue = React.useMemo(() => {
		if (multiple) {
			return options.filter((o) => value.includes(o.ClubId));
		} else {
			return options.find((o) => o.ClubId === value) ?? null;
		}
	}, [options, multiple, value]);

	return (
		<Autocomplete
			id="club-select"
			multiple={multiple}
			value={selectedValue}
			loading={loadingOptions}
			disableClearable
			options={options}
			slotProps={{
				paper: {
					elevation: 3,
				},
				popper: {
					keepMounted: true,
					sx: {
						// Remove top padding from the list in the popover
						".MuiAutocomplete-listbox": {
							paddingTop: 0,
						},
					},
				},
			}}
			groupBy={(option) => option.DivisionName}
			getOptionLabel={(option) => option.ClubName}
			onChange={(e, value) => {
				onValueChange(value);
			}}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					id="club-select-text-input"
					{...params}
					label={label}
					InputProps={{
						...params.InputProps,
						startAdornment: loadingOptions ? (
							<CircularProgress color="inherit" size={20} />
						) : !multiple && selectedValue ? (
							<img
								src={selectedValue.ClubImageUrl}
								alt={selectedValue.ClubName}
								style={{ width: 24, height: 24, marginRight: 10 }}
							/>
						) : (
							params.InputProps.startAdornment
						),
					}}
				/>
			)}
			renderTags={(tagValue, getTagProps) => {
				return tagValue.map((option, index) => {
					const { key, ...props } = getTagProps({ index });
					return (
						<Chip
							avatar={<Avatar alt="Club shield" src={option.ClubImageUrl} />}
							key={key}
							{...props}
							label={option.ClubName}
						/>
					);
				});
			}}
			//  Use HTML primitives for better performance: https://github.com/mui/material-ui/issues/34712#issuecomment-2187714626
			renderGroup={(params) => (
				<li key={params.key}>
					<div style={groupHeaderStyle}>{params.group}</div>
					<ul style={groupItemsListStyle}>{params.children}</ul>
				</li>
			)}
			//  Use HTML primitives for better performance: https://github.com/mui/material-ui/issues/34712#issuecomment-2187714626
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<li key={key} {...optionProps}>
						<img
							src={option.ClubImageUrl}
							alt={option.ClubName}
							style={{ width: 24, height: 24, marginRight: 20 }}
						/>
						{option.ClubName}
					</li>
				);
			}}
			{...props}
		/>
	);
};

export default ClubSelect;
