export const descriptions = {
	transferKpiRisk: `Risk classification is based on how the Transfer KPI is distributed in the squad. 
                                                                                      If few players account for a big part of the total Transfer KPI, your risk is higher, 
                                                                                      and vice versa.`,

	transferKpiComparison: `Goalunit Transfer KPI for the men’s first team in recent seasons, as well as the 
                                                                                            average in the league the club played in.`,

	transferKpiNotes: [
		<li style={{ marginBottom: "8px" }}>
			Remember to use the gameweek slider above the chart, to see your development during
			the season
		</li>,
		<li style={{ marginBottom: "8px" }}>
			Players on loan at the club will have 0 Transfer KPI and players away on loan will
			not be visible.
		</li>,
		<li style={{ marginBottom: "8px" }}>
			All markers have the same size on small screens for readability purposes.
		</li>,
	],
	transferKpi: `Size and color of each player's marker depends on the player's Transfer KPI.
                                     The bigger and greener the color, the higher the Transfer KPI.
                                      The KPI is a function of age index, remaining months on contract, position index, share of playing time, and share of points relative to position.
                                      The Transfer KPI shows each players potential for generating transfer revenue.`,

	powerRank: `The graph shows Goalunit's Power Rank for all clubs in the data base, i.e. ranks all clubs based 
                                    on their sporting competitiveness. The power rank is calculated based on ELO-methodology, 
                                    a commonly accepted ranking algorithm within, for example, chess.`,

	playTimeDistribution: `The graph shows share of playing time allocated by player age category per year 
                                                                                                      (accumulated for the current year). Age categories are 20 years and younger, 
                                                                                                      21-23, 24-28, 29 and older.`,

	marketValueComparison: `The graph shows the average total market value for the team per year 
                                                 (bars, mEUR), as well as the market value as a share of the total domestic market 
                                                 value (line).`,
	marketValueComparisonNotes: [
		<li style={{ marginBottom: "8px" }}>
			Domestic market value: Market value for players in the top 3 domestic leagues.
		</li>,
	],

	playerMarketValueAssessment: `The graph shows the development of the market value 
                                                    (as a share of total squad market value) per category Growth, 
                                                    Value, Seniority, and Cost. These categories correspond to the 
                                                    quadrants in the Transfer KPI-plot, i.e: Growth (Below 25 years 
                                                    of age & below 50% share of playing time), Value (Below 25 years 
                                                    of age & above 50% share of playing time), Seniority (Above 25 
                                                    years of age & above 50% share of playing time), and Cost 
                                                    (Above 25 years of age & below 50% share of playing time).`,

	marketValueAssessmentComparisonSubTitle: `The graphs below display the absolute average market values for each category,
					including teams from the same league.`,

	marketValueAssessmentComparisonGrowth: `Young talents, 25 and under, playing less than 50% of the time. High potential 
                                                                                                                      for development and future impact.`,

	marketValueAssessmentComparisonValue: `Rising stars, 25 and under, playing more than 50% of the time. Proven contributors 
                                                                                                                     with significant impact.`,

	marketValueAssessmentComparisonSeniority: `Experienced players, 26 and older, playing more than 50% of the time. 
                                                                                                                         Key contributors with leadership and stability.`,

	marketValueAssessmentComparisonOppCost: `Veterans, 26 and older, playing less than 50% of the time.
     
    Offer valuable skills and insights for specific situations.`,

	playerMarketValueAssessment: `The graph shows the share of market value per player category within the club's squad.`,

	marketValueAssessmentComparisonOppCost: `Veterans, 26 and older, playing less than 50% of the time. 
                                                                  Offer valuable skills and insights for specific situations.`,

	performanceComparison: `The graph features a scatter plot where the X-axis shows average net resources utilized (in mEUR) over three years, 
						and the Y-axis represents average competitiveness (Power rank) over the same period. Each point on the graph represents a club, plotted according 
						to its financial investment and competitive ranking. The red trend line indicates the expected level of competitiveness for a given net resources utilized level. 
						Clubs positioned above the trend line are outperforming, achieving higher competitiveness than expected for their utilization level, while those below the 
						trend line are underperforming compared to clubs with similar financial investment.`,
};
