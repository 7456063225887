import React, { useEffect, useState } from "react";
import { AccountCircle, Circle, CreditCard, Home } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	styled,
	Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { profileStore } from "../State/ProfileStore";
import { auth0Service } from "../Util/Auth0Client";
import { openChargebeePortal } from "./utilFunctions";

const CustomButton = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	"&:hover": {
		backgroundColor: theme.palette.info.dark,
		color: theme.palette.common.white,
	},
}));

const MyAccount = observer(() => {
	const [customerData, setCustomerData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getCustomerInfo = async () => {
		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };

			const response = await fetch("/api/chargebee/customer", {
				method: "GET",
				headers: header,
				credentials: "include",
			});
			const data = await response.json();
			setCustomerData(data);
		} catch (error) {
			console.log("Something went wrong", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getCustomerInfo();
	}, []);

	return isLoading ? (
		<Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
			<CircularProgress />
		</Box>
	) : (
		<Box>
			<Card
				sx={{
					boxShadow: 5,
					borderRadius: 2,
					backgroundColor: "background.light",
					height: "max-content",
				}}
			>
				<CardHeader
					title="My Account"
					titleTypographyProps={{ align: "center", variant: "h3" }}
					sx={{
						paddingBottom: 0,
						paddingTop: 2,
						position: "relative",
					}}
					action={
						<CustomButton
							variant="outlined"
							onClick={openChargebeePortal}
							sx={{
								position: "absolute",
								top: "38%",
								right: 30,
								transform: "translateY(-50%)",
							}}
						>
							Edit
						</CustomButton>
					}
				/>
				<CardContent>
					<Divider sx={{ mb: 2 }} />
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
						}}
					>
						<Box sx={{ flex: 1, px: 2 }}>
							<Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
								<AccountCircle sx={{ verticalAlign: "middle", mr: 1 }} />
								User
							</Typography>
							<Divider sx={{ mb: 1 }} />
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Name</span>
								<br />
								{customerData.customer?.first_name} {customerData.customer?.last_name}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Email</span>
								<br />
								{customerData.customer?.email}
							</Typography>
							<Typography variant="body1" sx={{ mb: 0 }}>
								<span style={{ fontWeight: 700 }}>Roles</span>
							</Typography>
							<List dense sx={{ alignItems: "start", mt: 0 }}>
								{profileStore?.userRoles.map((userrole, index) => (
									<ListItem key={index}>
										<ListItemIcon sx={{ minWidth: 20 }}>
											<Circle sx={{ fontSize: 8, color: "#000000" }} />
										</ListItemIcon>
										<ListItemText>
											<Typography variant="body1" sx={{ mt: 0, mb: 0 }}>
												{userrole.description}
											</Typography>
										</ListItemText>
									</ListItem>
								))}
							</List>

							<CustomButton variant="contained" onClick={profileStore.sendEmail}>
								Reset password
							</CustomButton>
							{profileStore.sendEmailStatus && (
								<Typography variant="body1" sx={{ mt: 1 }}>
									{profileStore.sendEmailStatus}
								</Typography>
							)}
						</Box>
						<Divider
							orientation="vertical"
							flexItem
							sx={{ display: { xs: "none", md: "block" } }}
						/>
						<Box sx={{ flex: 1, px: 2 }}>
							<Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
								<Home sx={{ verticalAlign: "middle", mr: 1 }} />
								Address
							</Typography>
							<Divider sx={{ mb: 1 }} />
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Street</span>
								<br />
								{customerData.customer?.billing_address?.line1}
								{customerData.customer?.billing_address?.line2 && (
									<>
										<br />
										{customerData.customer.billing_address.line2}
									</>
								)}
								{customerData.customer?.billing_address?.line3 && (
									<>
										<br />
										{customerData.customer.billing_address.line3}
									</>
								)}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>City</span>
								<br />
								{customerData.customer?.billing_address?.city}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>State</span>
								<br />
								{customerData.customer?.billing_address?.state}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>ZIP</span>
								<br />
								{customerData.customer?.billing_address?.zip}
							</Typography>
						</Box>
						<Divider
							orientation="vertical"
							flexItem
							sx={{ display: { xs: "none", md: "block" } }}
						/>
						<Box sx={{ flex: 1, px: 2 }}>
							<Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
								<CreditCard sx={{ verticalAlign: "middle", mr: 1 }} />
								Payment Method
							</Typography>
							<Divider sx={{ mb: 1 }} />
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Type</span>
								<br />
								{customerData.card?.card_type}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Card Number</span>
								<br />
								{customerData.card?.masked_number}
							</Typography>
							<Typography variant="body1">
								<span style={{ fontWeight: 700 }}>Expiry Date</span>
								<br />
								{customerData.card?.expiry_month && customerData.card?.expiry_year
									? `${customerData.card.expiry_month}/${customerData.card.expiry_year}`
									: ""}
							</Typography>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
});

export default MyAccount;
