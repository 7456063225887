import { auth0Service } from "../Util/Auth0Client";

export default class Position {
	data = [];
	isLoading = false;
	error = null;

	fetchData = async () => {
		this.setLoading(true);
		this.setError(null);

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch("/api/positions", {
				headers: header,
				credentials: "include",
			});
			const positionsData = await response.json();
			this.setData(positionsData);
			return this.data;
		} catch (error) {
			this.setError(error.message);
		} finally {
			this.setLoading(false);
		}
	};

	setData(newData) {
		this.data = newData;
	}

	setLoading(loading) {
		this.isLoading = loading;
	}

	setError(error) {
		this.error = error;
	}
}

export const position = new Position();
