import React, { useEffect, useRef, useState } from "react";
import { Switch } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./Dashboard.css";
import "../Style/am_style.css";
import { color } from "../Style/theme";
import { EUR } from "../Util/textFormatting";
import useDebounce from "../Util/useDebounce";

Chart.register(...registerables);
const chartOptions = {
	scales: {
		x: {
			display: false,
			grid: {
				lineWidth: 2,
			},
			border: {
				width: 2,
			},
		},
		y: {
			ticks: {
				stepSize: 20,
				font: {
					size: 14,
				},
			},
			grid: {
				lineWidth: 2,
			},
			border: {
				width: 2,
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: function (ctx) {
					return `${Math.round(ctx.parsed.y)}%`;
				},
			},
		},
		watermark: false,
	},
};

const backgroundcolorsNotSelected = [
	color.primary.main,
	color.secondary.main,
	color.warning.main,
	color.error.main,
];

const backgroundcolorsSelected = [
	color.primary.light,
	color.secondary.main,
	color.warning.main,
	color.error.main,
];

const ClubKPIs = ({ cardsKPIData, selectedCards, onCardClick }) => {
	const [powerRank, setPowerRank] = useState(null);
	const [localPowerRank, setLocalPowerRank] = useState(null);
	const [areaName, setAreaName] = useState(null);
	const [powerRankSeasonName, setPowerRankSeasonName] = useState(null);
	const [squadValue, setSquadValue] = useState(null);
	const [squadValuePercentage, setSquadValuePercentage] = useState(null);
	const [localSquadValueRank, setLocalSquadValueRank] = useState(null);
	const [transferKPI, setTransferKPI] = useState(null);
	const [transferKPIChange, setTransferKPIChange] = useState(null);
	const [transferKPIRiskLevel, setTransferKPIRiskLevel] = useState(null);
	const [PPTDB, setPPTDB] = useState(null);
	const [GVSO, setGVSO] = useState({
		datasets: [{ data: [] }],
		labels: [],
	});
	const chartRef = useRef(null);

	const extractData = (data) => {
		if (!data || Object.keys(data).length === 0) return;

		// Process and update KPI-related state variables
		const KPIData = data.KPI || [];
		const max = Math.max(...KPIData.map((d) => d.MatchesPlayedByTeam));
		const currentTransferKPI = KPIData.filter((d) => d.MatchesPlayedByTeam === max);
		const previousTransferKPI = KPIData.filter((d) => d.MatchesPlayedByTeam === max - 1);

		const sumCurrentTransferKPI = currentTransferKPI.reduce(
			(total, item) => total + item.TransferKPI,
			0
		);
		const sumPreviousTransferKPI = previousTransferKPI.reduce(
			(total, item) => total + item.TransferKPI,
			0
		);

		let KPIRatio = (100 * (sumCurrentTransferKPI / sumPreviousTransferKPI - 1)).toFixed(
			0
		);

		KPIRatio = isNaN(KPIRatio) || !isFinite(KPIRatio) ? "-" : KPIRatio;

		setTransferKPI(sumCurrentTransferKPI.toFixed(1));
		setTransferKPIChange(KPIRatio);

		// Process TKR
		const numberOfRows = data.TKR?.length || 0;
		let transferLevel;
		if (numberOfRows > 4) {
			transferLevel = "Low";
		} else if (numberOfRows >= 3 && numberOfRows <= 4) {
			transferLevel = "Medium";
		} else if (numberOfRows < 3 && numberOfRows !== 0) {
			transferLevel = "High";
		} else {
			transferLevel = "Not available";
		}
		setTransferKPIRiskLevel(transferLevel);

		// Process MCV (Squad Value)
		const SquadValue = (data.MCV || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const CurrentSeasonSquadValue = SquadValue[0] || {};

		setSquadValue(EUR.format(CurrentSeasonSquadValue.ClubMarketValueSum || 0));
		setSquadValuePercentage(
			CurrentSeasonSquadValue.DomesticPercentage?.toFixed(0) || "0"
		);
		setLocalSquadValueRank(CurrentSeasonSquadValue.LocalRank || null);

		// Process PR (Power Rank)
		const PowerRank = (data.PR || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const CurrentPowerRank = PowerRank[0] || {};
		setPowerRank(CurrentPowerRank.PowerRank || null);
		setAreaName(CurrentPowerRank.AreaName || null);
		setLocalPowerRank(CurrentPowerRank.LocalPowerRank || null);
		setPowerRankSeasonName(CurrentPowerRank.SeasonName || null);

		// Process PTD (Play Time Distribution)
		const playTimeDistributionData = (data.PTD || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const currentSeasonName = playTimeDistributionData[0]?.SeasonName || null;
		const currentSeason = playTimeDistributionData.filter(
			(d) => d.SeasonName === currentSeasonName
		);
		const playedMatches = Math.max(...currentSeason.map((d) => d.MatchNo), 0);
		const latestStats = currentSeason.filter((d) => d.MatchNo === playedMatches);

		let ageCategories = new Set();
		latestStats.forEach((item) => ageCategories.add(item.AgeCategory));
		const ageCategoriesOrder = Array.from(ageCategories).sort((a, b) => {
			const getLowestNumber = (str) => parseInt(str.match(/\d+/)?.[0] || "0");
			return getLowestNumber(a) - getLowestNumber(b);
		});

		const filteredData = latestStats.filter((d) =>
			ageCategoriesOrder.slice(0, 2).includes(d.AgeCategory)
		);
		setPPTDB(
			filteredData.reduce((total, item) => total + item.PartOfPlayingTime, 0).toFixed(0)
		);

		// Process PMA
		const currentsGVSO = (data.PMA || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const currentSeasonGVSO = currentsGVSO[0] || {};

		setGVSO({
			datasets: [
				{
					data: [
						currentSeasonGVSO.GrowthSum || 0,
						currentSeasonGVSO.ValueSum || 0,
						currentSeasonGVSO.SenioritySum || 0,
						currentSeasonGVSO.OppCostSum || 0,
					],
					backgroundColor: backgroundcolorsNotSelected,
					borderColor: backgroundcolorsNotSelected,
				},
			],
			labels: ["Growth", "Value", "Seniority", "Opportunity Cost"],
		});
	};

	useEffect(() => {
		if (cardsKPIData) {
			extractData(cardsKPIData);
		}
	}, [cardsKPIData]);

	const debouncedSelectedCard = useDebounce(selectedCards, 200);

	useEffect(() => {
		let updatedChartData = {
			...GVSO,
			datasets: [
				{
					...GVSO.datasets[0],
					backgroundColor: selectedCards.includes("GVSO")
						? backgroundcolorsSelected
						: backgroundcolorsNotSelected,
					borderColor: selectedCards.includes("GVSO")
						? backgroundcolorsSelected
						: backgroundcolorsNotSelected,
				},
			],
		};
		chartRef.current.options.scales.x.ticks.color = selectedCards.includes("GVSO")
			? "white"
			: "black";
		chartRef.current.options.scales.x.grid.color = selectedCards.includes("GVSO")
			? "rgba(255, 255, 255, 0.1)"
			: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.x.border.color = selectedCards.includes("GVSO")
			? "rgba(255, 255, 255, 0.1)"
			: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.y.ticks.color = selectedCards.includes("GVSO")
			? "white"
			: "black";
		chartRef.current.options.scales.y.grid.color = selectedCards.includes("GVSO")
			? "rgba(255, 255, 255, 0.1)"
			: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.y.border.color = selectedCards.includes("GVSO")
			? "rgba(255, 255, 255, 0.1)"
			: "rgba(0, 0, 0, 0.1)";

		setGVSO(updatedChartData);
	}, [debouncedSelectedCard]);

	return (
		<div className="dashboard-background">
			<div className="dashboard-KPI-container">
				<div
					className={`dashboard-KPI-card ${selectedCards.includes("transferKPI") ? "selected" : ""}`}
					onClick={() => onCardClick(["transferKPI +1", "transferKPI"])}
				>
					<div className="dashboard-KPI-card-title">Transfer KPI</div>
					<div className="dashboard-KPI-card-value">{transferKPI}</div>
					<div className="dashboard-KPI-card-subtitle">
						<span style={{ color: transferKPIChange < 0 ? "red" : "green" }}>
							{transferKPIChange}%
						</span>{" "}
						since last update
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCards.includes("squadValue") ? "selected" : ""}`}
					onClick={() => onCardClick(["squadValue"])}
				>
					<div className="dashboard-KPI-card-title">Average Squad value</div>
					<div className="dashboard-KPI-card-value">{squadValue}</div>
					<div className="dashboard-KPI-card-subtitle">
						{squadValuePercentage}% of {areaName} market value
					</div>
					<div className="dashboard-KPI-card-subtitle" style={{ marginTop: 8 }}>
						Rank {localSquadValueRank} in {areaName}
					</div>
				</div>
				<div
					className={`dashboard-KPI-card ${selectedCards.includes("powerRank") ? "selected" : ""}`}
					onClick={() => onCardClick(["powerRank"])}
				>
					<div className="dashboard-KPI-card-title">Power rank {powerRankSeasonName}</div>
					<div className="dashboard-KPI-card-value">{powerRank}</div>
					<div className="dashboard-KPI-card-subtitle">
						Rank {localPowerRank} in {areaName}
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCards.includes("transferKPIRiskLevel") ? "selected" : ""}`}
					onClick={() => onCardClick(["transferKPIRiskLevel"])}
				>
					<div className="beta-tag">Beta</div>
					<div className="dashboard-KPI-card-title">Transfer KPI Risk</div>
					<div className="dashboard-KPI-card-value">
						<span
							style={{
								color:
									transferKPIRiskLevel === "High"
										? "red"
										: transferKPIRiskLevel === "Medium"
											? "orange"
											: "green",
							}}
						>
							{transferKPIRiskLevel ? transferKPIRiskLevel : "Not available"}
						</span>
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCards.includes("playTimeDistribution") ? "selected" : ""}`}
					onClick={() => onCardClick(["playTimeDistribution"])}
				>
					<div className="dashboard-KPI-card-title">Part of playing time</div>
					<div className="dashboard-KPI-card-value">
						<span style={{ color: PPTDB < 50 ? "inherit" : "green" }}>{PPTDB} %</span>
					</div>
					<div className="dashboard-KPI-card-subtitle">
						For players under the age of 24{" "}
					</div>
				</div>
				<div
					className={`dashboard-KPI-card ${selectedCards.includes("GVSO") ? "selected" : ""}`}
					onClick={() =>
						onCardClick(["GVSO +4", "GVSO +3", "GVSO +2", "GVSO +1", "GVSO"])
					}
				>
					<div className="dashboard-KPI-card-title">Share of MV per category</div>
					<div className="dashboard-KPI-card-value">
						<Bar ref={chartRef} data={GVSO} options={chartOptions} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClubKPIs;
