import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	Typography,
} from "@mui/material";
import { capitalizeString } from "../Util/textFormatting";
import { getCustomerSubscription } from "./utilFunctions";

const Nextbilling = () => {
	const [subscriptionData, setSubscriptionData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [nextBillingDate, setNextBillingDate] = useState([]);

	const formatStatus = (status) => {
		if (!status) return;
		return capitalizeString(status.replaceAll("_", " "));
	};

	const convertToDate = (timestamp) => {
		if (timestamp > 0) {
			return new Date(timestamp * 1000).toLocaleDateString();
		} else {
			return null;
		}
	};

	const getTotalAmount = (items) => {
		return items?.reduce((total, item) => total + (item.amount || 0), 0) / 100.0;
	};

	const customerSubscription = async () => {
		const customerSubscription = await getCustomerSubscription();
		const nextBillingTimeStamp = customerSubscription?.next_billing_at;

		setSubscriptionData(customerSubscription);
		setNextBillingDate(convertToDate(nextBillingTimeStamp));
		setIsLoading(false);
	};

	useEffect(() => {
		customerSubscription();
	}, []);

	return isLoading ? (
		<Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
			<CircularProgress />
		</Box>
	) : (
		subscriptionData?.id && (
			<Box>
				<Card
					sx={{
						boxShadow: 5,
						borderRadius: 2,
						backgroundColor: "background.light",
						height: "max-content",
					}}
				>
					<CardHeader
						avatar={<AccessTimeIcon color="primary" />}
						title="Next billing"
						titleTypographyProps={{ align: "left", variant: "h5" }}
						sx={{
							paddingBottom: 0,
							paddingTop: 2,
							position: "relative",
						}}
					/>
					<CardContent>
						<Divider sx={{ mb: 2 }} />
						<Box
							sx={{
								display: "flex",
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box sx={{ flex: 1, px: 2 }}>
								<Typography variant="body1">
									<strong>Subscription status</strong>
									<br />
									{formatStatus(subscriptionData?.status)}
								</Typography>
								<Divider sx={{ mb: 2 }} />
								{subscriptionData?.status === "non_renewing" && (
									<Typography variant="body1">
										<strong>Subscription ends</strong>
										<br />
										{convertToDate(subscriptionData?.current_term_end)}
									</Typography>
								)}
								{subscriptionData?.status === "cancelled" && (
									<Typography variant="body1">
										<strong>Cancelled on</strong>
										<br />
										{convertToDate(subscriptionData?.cancelled_at)}
									</Typography>
								)}
								{nextBillingDate && (
									<>
										<Typography variant="body1">
											<strong>Date</strong>
											<br />
											{nextBillingDate}
										</Typography>
										<Divider sx={{ mb: 2 }} />
										<Typography variant="body1">
											<strong>Amount</strong>
											<br />€ {getTotalAmount(subscriptionData?.subscription_items)}.00
										</Typography>
									</>
								)}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		)
	);
};
export default Nextbilling;
