export const getGradientBg = (color) => {
	return function (context) {
		const chart = context.chart;
		const { ctx, chartArea } = chart;

		if (!chartArea) {
			// This case happens on initial chart load
			return;
		}
		var gradient = ctx.createLinearGradient(0, 0, 0, 400);
		gradient.addColorStop(0, color);
		gradient.addColorStop(1, "rgba(255,255,255,0)");
		return gradient;
	};
};
