import React, { useEffect, useState } from "react";
import { ChangeHistory, CircleOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { impersonationStore } from "../State/ImpersonationStore";
import { auth0Service } from "../Util/Auth0Client";
import { descriptions } from "../Util/chartDescriptions";
import ChartSection from "./components/ChartSection";
import PerformanceComparison from "./PerformanceComparison";

const noteItemStyle = {
	display: "flex",
	flexDirecition: "row",
	alignItems: "center",
	gap: "5px",
	marginBottom: "5px",
};

const Performance = () => {
	const [KPIvsFinancialData, setKPIvsFinancialData] = useState([]);
	const [KPIvsFinancialLeagueData, setKPIvsFinancialLeagueData] = useState([]);
	const [sessionClubId, setSessionClubId] = useState(null);

	const fetchData = async () => {
		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch("/api/financialperformance", {
				headers: header,
				credentials: "include",
			});

			const FD = await response.json();

			setKPIvsFinancialData(FD.KPIF ?? []);
			setKPIvsFinancialLeagueData(FD.KPIFL ?? []);
		} catch (error) {
			this.setError(error.message);
		}
	};

	useEffect(() => {
		const clubInfo = impersonationStore.extractClubInfo();
		setSessionClubId(clubInfo.ClubId);
		fetchData();
	}, []);

	return (
		<>
			<ChartSection
				title="Financial Performance"
				description={descriptions.performanceComparison}
				notes={[
					<li style={noteItemStyle}>
						<CircleOutlined fontSize="16" />
						<b>Circle:</b> Confirmed value
					</li>,
					<li style={noteItemStyle}>
						<ChangeHistory fontSize="16" />
						<b>Triangle:</b> Estimated value
					</li>,
				]}
				updateFrequency={"weekly"}
			>
				{KPIvsFinancialData.length > 0 ? (
					<PerformanceComparison
						data={KPIvsFinancialData}
						leagueData={KPIvsFinancialLeagueData}
						sessionClubId={sessionClubId}
					/>
				) : (
					<CircularProgress />
				)}
			</ChartSection>
		</>
	);
};
export default Performance;
