import React, { useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import ExportPDF from "../Components/ExportPDF";
import { dashboardStore } from "../State/DashboardStore";
import { impersonationStore } from "../State/ImpersonationStore";
import chartTitles from "../Util/chartTitles";
import ClubKPIs from "./ClubKPIs";
import DashboardChartContainer from "./DashboardChartContainer";
import NoCardsSelected from "./NoCardsSelected";

const availableCards = [
	"transferKPI",
	"transferKPI +1",
	"squadValue",
	"powerRank",
	"playTimeDistribution",
	"GVSO",
	"GVSO +1",
	"GVSO +2",
	"GVSO +3",
	"GVSO +4",
	"transferKPIRiskLevel",
];

const Dashboard = observer(() => {
	const data = toJS(dashboardStore.data);

	const [reportView, setReportView] = useState(false);
	const [selectedCards, setSelectedCards] = useState(["transferKPI", "transferKPI +1"]);
	const chartRefs = useRef([]);

	const clubName = impersonationStore.extractClubInfo().ClubName;

	const handleCardClick = (cards) => {
		setSelectedCards((prevSelected) => {
			let newSelected = [...prevSelected];

			cards.forEach((card) => {
				if (newSelected.includes(card)) {
					newSelected = newSelected.filter((c) => c !== card);
				} else {
					newSelected.unshift(card);
				}
			});

			return newSelected;
		});
	};

	useEffect(() => {
		if (reportView) {
			const updatedSelection = [
				...selectedCards,
				...availableCards.filter((card) => !selectedCards.includes(card)),
			];
			setSelectedCards(updatedSelection);
		} else {
			setSelectedCards(["transferKPI", "transferKPI +1"]);
		}
	}, [reportView]);

	const handleSwitch = () => {
		setReportView((prevReportView) => !prevReportView);
	};

	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: { xs: "center", md: "flex-end" },
						alignItems: "center",
						gap: { xs: 2, md: 3 },
						mb: 2,
						order: { xs: 1, md: 0 },
					}}
				>
					<FormControlLabel
						control={<Switch checked={reportView} onChange={handleSwitch} />}
						label="Select all"
						labelPlacement="start"
						disableTypography
						sx={{
							fontSize: "16px",
							whiteSpace: "nowrap",
						}}
					/>
					<ExportPDF
						titles={selectedCards.map((card) => chartTitles[card])}
						refs={chartRefs}
						coverTitle={clubName}
					/>
				</Box>
				<ClubKPIs
					cardsKPIData={data}
					selectedCards={selectedCards}
					onCardClick={handleCardClick}
				/>
			</Box>
			{selectedCards.length === 0 ? (
				<NoCardsSelected />
			) : (
				<DashboardChartContainer
					data={data}
					selectedCards={selectedCards}
					chartRefs={chartRefs}
				/>
			)}
		</>
	);
});

export default Dashboard;
