import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { Scatter } from "react-chartjs-2";
import "chart.js/auto";
import CustomTable from "../../Components/Table";
import "../../Style/style.css";
import Rank from "./Rank";

const wrapperStyle = {
	display: "grid",
	gridTemplateColumns: { xs: "1fr", xl: "1fr 50% 2fr" },

	alignItems: "center",
	width: "100%",
	gap: { xs: 4, md: 6, xl: 2 },
};

const chartWrapperStyle = {
	height: "400px",
	width: "100%",
	maxWidth: "90vw",
	justifySelf: "center",
};

const tableWrapperStyle = {
	paddingBottom: { xs: 0, xl: 4 },
};

Chart.register(...registerables);

const CategorySection = ({ name, chartData, tableData, ranking, maxMarketValue }) => {
	const chartRef = useRef(null);
	const [tableColumns] = useState([
		{ field: "clubName", headerName: "Club" },
		{ field: "Rank", headerName: "Rank", customStyle: { textAlign: "center" } },
	]);

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			x: {
				type: "linear",
				position: "bottom",
				title: {
					display: true,
					text: "Market value (mEUR)",
				},
				min: 0,
				max: maxMarketValue,
				ticks: {
					font: {
						size: 15,
					},
					callback: function (value, index) {
						return value / 1000000; // display in million €
					},
				},
			},
			y: {
				display: false,
				min: -0.5,
				max: 0.5,
			},
		},
		plugins: {
			annotation: {
				annotations: {
					line1: {
						type: "line",
						yMin: 0,
						yMax: 0,
						xMin: 0,
						xMax: maxMarketValue,
						borderColor: "grey",
						borderWidth: 0.1,
					},
				},
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const dataPoint = context.raw;
						return dataPoint.clubName ? `Club: ${dataPoint.clubName}` : "";
					},
				},
			},
			legend: {
				display: false,
			},
		},
	};

	return (
		<Box sx={wrapperStyle}>
			<Rank ranking={ranking} />

			<Box sx={chartWrapperStyle}>
				{chartData ? (
					<div style={{ position: "relative", width: "100%", height: "100%" }}>
						<Scatter data={chartData} options={options} ref={chartRef} />
					</div>
				) : (
					<p>Loading {name} data...</p>
				)}
			</Box>
			<Box sx={tableWrapperStyle}>
				<CustomTable tableData={tableData} tableColumns={tableColumns} />
			</Box>
		</Box>
	);
};

export default CategorySection;
