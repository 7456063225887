import React from "react";
import { styled, Typography } from "@mui/material";
import logo from "../Logos/GoalunitLogo_BrightVertical.svg";
import { color } from "../Style/theme";
import { InnerWrapper, LandingPageElement } from "./components";

const CustomLandingPageElement = styled(LandingPageElement)(({ theme }) => ({
	[theme.breakpoints.up("lg")]: {
		alignItems: "end",
		minHeight: "85vh",
		paddingBottom: theme.spacing(12),
	},
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: color.background.main,

	[theme.breakpoints.up("lg")]: {
		animationDuration: "2s",
		animationName: "floatAnimationX",
	},
}));

const Logo = styled("img")(({ theme }) => ({
	height: "100px",
	animationName: "floatAnimationY",
	animationDuration: "2s",
	display: "block",
	margin: "0 auto",

	[theme.breakpoints.up("sm")]: {
		height: "130px",
	},
	[theme.breakpoints.up("lg")]: {
		height: "180px",
	},
	[theme.breakpoints.up("xxxl")]: {
		height: "230px",
	},
}));

function Home() {
	return (
		<CustomLandingPageElement name="home" id="home">
			<InnerWrapper>
				<Logo src={logo} alt="Goalunit" />
				<StyledTypography variant="h2" component="h1">
					Elevating Football Strategy
				</StyledTypography>
			</InnerWrapper>
		</CustomLandingPageElement>
	);
}

export default Home;
