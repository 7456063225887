import { React, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import Box from "@mui/material/Box";
import { Chart, registerables } from "chart.js";
import { color } from "../Style/theme";
import { seasonNameShortener } from "../Util/textFormatting";

Chart.register(...registerables);

const PlayerMarketValueAssessment = (props) => {
	const [chartData, setChartData] = useState({
		datasets: [],
	});

	const chartRef = useRef(null);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Season",
				},
				ticks: {
					callback: function (value) {
						const scale = this;
						const season = scale.getLabelForValue(value);
						return seasonNameShortener(season);
					},
				},
			},
			y: {
				title: {
					display: true,
					text: "Percent (%)",
				},
				beginAtZero: true,
				max: 100,
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "top",
				labels: {
					padding: 20,
					font: {
						size: 16, // Adjust this value to change the font size
					},
				},
			},
			tooltip: {
				callbacks: {
					labelColor: (context) => {
						const dataset = context.dataset;
						return {
							borderColor: dataset.backgroundColor,
							backgroundColor: dataset.backgroundColor,
						};
					},
					label: function (context) {
						let label = context.dataset.label || "";
						if (label) {
							label += ": ";
						}
						if (context.parsed.y !== null) {
							label += Math.round(context.parsed.y) + "%";
						}
						return label;
					},
				},
				itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // reverse order of items in tooltip
			},
			crosshair: {
				color: color.primary.contrast,
			},
		},
	};

	const loadData = (jsonData) => {
		var data = jsonData.filter((item) => item.SeasonName);
		data.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));

		const seasons = data.map((item) => item.SeasonName);
		const growthSum = data.map((item) => item.GrowthSum);
		const valueSum = data.map((item) => item.ValueSum);
		const senioritySum = data.map((item) => item.SenioritySum);
		const oppCostSum = data.map((item) => item.OppCostSum);

		// Att dela upp de så här kommer inte säkerställa att rätt värde kommer för rätt säsong.
		// Det innebär att vi kommer att behöva göra om här lite.

		const categories = [
			{
				label: "Growth",
				data: growthSum,
				backgroundColor: color.primary.main,
			},
			{
				label: "Value",
				data: valueSum,
				backgroundColor: color.secondary.main,
			},
			{
				label: "Seniority",
				data: senioritySum,
				backgroundColor: color.warning.main,
			},
			{
				label: "Opportunity cost",
				data: oppCostSum,
				backgroundColor: color.error.main,
			},
		];
		const datasets = categories.map((d) => ({
			...d,
			fill: true,
			borderColor: color.primary.contrast,
			borderWidth: 0,
		}));
		setChartData({ labels: seasons, datasets });
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	/*
		Important note! Chart.js uses its parent container to update the canvas render and display sizes. 
		This method requires the container to be relatively positioned and 
		dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
		relative values for the container size
	*/
	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				maxHeight: { sx: "80vh", lg: "50vh" },
			}}
		>
			<Line
				data={chartData}
				options={chartOptions}
				width={600}
				height={500}
				ref={chartRef}
			/>
		</Box>
	);
};

export default PlayerMarketValueAssessment;
