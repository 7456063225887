import React from "react";
import "../Style/style.css";
import "../Style/am_style.css";
import { Typography } from "@mui/material";
import theme from "../Style/theme";
import { InnerWrapper, LandingPageElement } from "./components";

function About() {
	return (
		<LandingPageElement name="about" id="about" light_bg="true">
			<InnerWrapper sx={{ maxWidth: "920px" }}>
				<Typography variant="h2">About</Typography>
				<Typography
					variant="body1"
					sx={{ textAlign: "center", padding: theme.spacing(0, 2) }}
				>
					We founded Goalunit with the purpose of revolutionizing the decision-making
					within European football, through delivering relevant, contextual data insights.
					Our goal is to support clubs in their journey to become over-performing on and
					off the pitch.
				</Typography>
			</InnerWrapper>
		</LandingPageElement>
	);
}

export default About;
