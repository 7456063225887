import * as Icons from "@mui/icons-material";
import { auth0Service } from "../Util/Auth0Client";

export const openChargebeePortal = async () => {
	try {
		const token = await auth0Service.getToken();
		const header = { Authorization: `Bearer ${token}` };
		const response = await fetch("/api/chargebee/portal", {
			method: "POST",
			headers: header,
			credentials: "include",
		});
		const portalsession = await response.json();
		window.location.href = portalsession.portal_session.access_url;
	} catch (error) {
		console.log("Something went wrong", error);
	}
};

export const chargebeeCheckout = async (subscriptionId, entityId) => {
	try {
		const token = await auth0Service.getToken();
		const header = { Authorization: `Bearer ${token}` };
		const response = await fetch(
			`/api/chargebee/customer/subscription?subscriptionId=${subscriptionId}&entityId=${entityId}`,
			{
				method: "POST",
				headers: header,
				credentials: "include",
			}
		);

		const hostedpage_response = await response.json();
		localStorage.setItem("shouldStartPolling", "true");
		window.location.href = hostedpage_response.url;
	} catch (error) {
		console.log("Something went wrong", error);
	}
};

export const getCustomerSubscription = async () => {
	try {
		const token = await auth0Service.getToken();
		const header = { Authorization: `Bearer ${token}` };

		const response = await fetch("/api/chargebee/customer/subscription", {
			method: "GET",
			headers: header,
			credentials: "include",
		});
		const data = await response.json();
		if (!data) return;
		return data[0];
	} catch (error) {
		console.log("Something went wrong", error);
	}
};

export const parseSubscriptionItems = (subscriptionItems) => {
	return new Set(
		subscriptionItems.map((item) => {
			const parts = item.item_price_id.split("-");
			return parts.slice(0, -2).join("-");
		})
	);
};

export const ItemIcon = ({ item, size = 75 }) => {
	const IconComponent = Icons[item.Item.metadata.muiiconname] || Icons.HelpOutline;
	return <IconComponent sx={{ mt: 1, fontSize: size }} />;
};
