import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet, useSearchParams } from "react-router-dom";
import { impersonationStore } from "../State/ImpersonationStore";
import { auth0Service } from "../Util/Auth0Client"; // Ensure this import is available

import MatchReportDashboard from "./MatchReportDashboard";

const MatchReport = observer(() => {
	const [matchesData, setMatchesData] = useState([]);
	const [impersonationInfo, setImpersonationInfo] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const fetchMatchReportData = async () => {
		setSearchParams({}); // reset searchParams
		setIsLoading(true);
		setError(null); // Reset error state before fetch

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch(`/api/matchreport/matches`, {
				headers: header,
				credentials: "include",
			});

			if (!response.ok) {
				throw new Error("Failed to matches");
			}

			const matchReportData = await response.json();

			setMatchesData(matchReportData || []);
		} catch (error) {
			setError(error.message);
			setMatchesData([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchMatchReportData();
		setImpersonationInfo(impersonationStore.extractClubInfo());
	}, []); // This will only run once when the component mounts

	if (isLoading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	}

	if (error) {
		return <div style={{ textAlign: "center" }}>Error: {error}</div>; // Display error message if exists
	}

	return (
		<div>
			<MatchReportDashboard data={matchesData} />
			{!!searchParams.get("matchId") ? (
				<>
					<Outlet
						context={{
							impersonationInfo,
						}}
					/>
				</>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<h2>No match report data found</h2>
				</div>
			)}
		</div>
	);
});

export default MatchReport;
