import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { profileStore } from "../src/State/ProfileStore";
import { userEntitlementStore } from "../src/State/UserEntitlementStore";
import useEmailVerificationCheck from "./Authorization/isEmailVerified";
import Profile from "./Authorization/profile";
import VerifyEmail from "./Authorization/VerifyEmail";
import BlurredOverlay from "./Components/BlurredOverlay/BlurredOverlay";
import Navbar from "./Components/Navbar/Navbar";
import SubscriptionConfirmation from "./Components/SubscriptionConfirmation/SubscriptionConfirmation";
import TermsAndConditions from "./Components/TermsAndConditions";
import ClubOverview from "./Datapage/ClubOverview";
import About from "./Landingpage/About";
import AM from "./Landingpage/AM";
import Contact from "./Landingpage/Contact";
import Home from "./Landingpage/Home";
import Services from "./Landingpage/Services";
import Team from "./Landingpage/Team";
import "./Style/style.css";
import "./Style/am_style.css";
import Dashboard from "./Datapage/Dashboard";
import Performance from "./Datapage/Performance";
import MatchReport from "./MatchReport/MatchReport";
import PostReportContainer from "./MatchReport/PostReportContainer";
import PreReportContainer from "./MatchReport/PreReportContainer";
import SquadPage from "./Squadpage/SquadPageOverview";
import theme from "./Style/theme";
import { routes } from "./Util/routes";

function LandingPage() {
	return (
		<div className="landing-page">
			<main>
				<Home />
				<AM />
				<Services />
				<About />
				<Team />
				<Contact />
			</main>
		</div>
	);
}

function Loading() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress />
		</div>
	);
}

const ProtectedRoute = observer(
	({ component: Component, featureId: FeatureId, entitlementType }) => {
		const { isLoading } = useAuth0();
		const [hasAccess, setHasAccess] = useState(false);

		useEffect(() => {
			if (userEntitlementStore.isLoaded) {
				const access = userEntitlementStore.checkFeatureAccess(FeatureId);
				setHasAccess(access);
			}
		}, [userEntitlementStore.entitlements, userEntitlementStore.isLoaded, FeatureId]);

		if (isLoading) {
			return <Loading />;
		}

		const ComponentWithAuth = withAuthenticationRequired(Component, {
			onRedirecting: () => <Loading />,
		});
		return userEntitlementStore.isLoaded &&
			!hasAccess &&
			!profileStore.isAdministrator() ? (
			<BlurredOverlay />
		) : (
			<ComponentWithAuth entitlementType={entitlementType} />
		);
	}
);

function App() {
	const location = useLocation();
	const [bgColor, setBgColor] = useState("#23374C");

	useEffect(() => {
		if (location.pathname.startsWith(routes.am.base)) {
			setBgColor("#F3F5F1");
		} else {
			setBgColor("#23374C");
		}
	}, [location.pathname]);

	useEffect(() => {
		// Activate polling for chargebee entitlements after being redirected to checkout portal
		if (localStorage.getItem("shouldStartPolling") === "true") {
			userEntitlementStore.startPolling();
			localStorage.removeItem("shouldStartPolling");
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<div style={{ backgroundColor: bgColor, minHeight: "100vh" }}>
				{useEmailVerificationCheck() ? (
					<VerifyEmail />
				) : (
					<div>
						<Navbar />
						<Routes location={location}>
							<Route path={routes.start.base} element={<LandingPage />} />
							<Route path={"/" + routes.start.home} element={<Home />} />
							<Route path={"/" + routes.start.services} element={<Services />} />
							<Route path={"/" + routes.start.about} element={<About />} />
							<Route path={"/" + routes.start.contact} element={<Contact />} />
							<Route path={"/" + routes.start.am} element={<AM />} />
							{/* TODO: Remove deprecated routes when route change is established */}
							<Route
								path={routes.am.clubDeprecated}
								element={<Navigate to={routes.am.club.base} />}
							/>
							<Route
								path={routes.am.squadDeprecated}
								element={<Navigate to={routes.am.club.squad} />}
							/>

							<Route
								path={routes.am.club.base}
								element={
									<ProtectedRoute
										component={ClubOverview}
										featureId="club-overview"
										entitlementType="subscription"
									/>
								}
							>
								<Route index element={<Dashboard />} />
								<Route path={routes.am.club.performance} element={<Performance />} />
								<Route path={routes.am.club.squad} element={<SquadPage />} />
								<Route
									path={routes.am.club.matchReport.base}
									element={
										<ProtectedRoute
											component={MatchReport}
											featureId="match-report"
											entitlementType="add-on"
										/>
									}
								>
									<Route index element={<PreReportContainer />} />
									<Route
										path={routes.am.club.matchReport.pre}
										element={<PreReportContainer />}
									/>
									<Route
										path={routes.am.club.matchReport.post}
										element={<PostReportContainer />}
									/>
								</Route>
							</Route>
							<Route
								path={routes.am.profile}
								element={<ProtectedRoute component={Profile} />}
							/>
							<Route
								path={routes.am.termsAndConditions}
								element={<TermsAndConditions />}
							/>
							<Route
								path={routes.am.subscriptionConfirmation}
								element={<SubscriptionConfirmation />}
							/>
						</Routes>
					</div>
				)}
			</div>
		</ThemeProvider>
	);
}

export default App;
