import School from "@mui/icons-material/SchoolRounded";
import { Chip, Tooltip } from "@mui/material";

const AcademyPlayerIcon = ({ sx, displayChip = false }) => {
	return displayChip ? (
		<Chip icon={<School color="secondary" />} label="Academy player" />
	) : (
		<Tooltip title="Academy player">
			<School color="secondary" sx={sx} />
		</Tooltip>
	);
};

export default AcademyPlayerIcon;
