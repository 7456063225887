const chartTitles = {
	transferKPI: "Transfer KPI",
	transferKPIComparison: "Transfer KPI Comparison",
	squadValue: "Squad Value",
	powerRank: "Power Rank",
	playTimeDistribution: "Play Time Distribution",
	transferKPIRiskLevel: "Transfer KPI Risk Level",
	GVSO: "Market Value Share per Category",
};

export default chartTitles;
