import React, { useState } from "react";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { auth0Service } from "../../Util/Auth0Client";
import ModalWithTitle from "../Modal/ModalWithTitle";

export function scrollToTop() {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
}

export function FeedbackForm({ isFormVisible, toggleForm }) {
	const [message, setMessage] = useState("");

	const closeForm = () => {
		toggleForm();
	};

	const handleSubmit = async () => {
		setMessage("");
		closeForm();

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			await fetch("/api/feedback", {
				method: "POST",
				headers: header,
				body: JSON.stringify({ message: message }),
			});
		} catch (error) {
			console.error("Error sending feedback:", error);
		}
	};

	return (
		<ModalWithTitle open={isFormVisible} onClose={closeForm}>
			<Typography sx={{ textAlign: "center" }}>
				We strive to make the Assistant Manager as useful as possible for our customers.
			</Typography>
			<Typography sx={{ textAlign: "center" }}>
				Feel free to send any thoughts and suggestions to us below!
			</Typography>
			<FormControl fullWidth sx={{ marginTop: "16px", marginBottom: "16px" }}>
				<TextField
					id="message"
					label=""
					multiline
					rows={6}
					variant="outlined"
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Button size="large" variant="contained" onClick={handleSubmit}>
					Submit
				</Button>
			</Box>
		</ModalWithTitle>
	);
}
