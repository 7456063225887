import React, { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import "chart.js/auto";
import { Public, Toc } from "@mui/icons-material";
import {
	Box,
	FormControlLabel,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import regression from "regression";
import CustomSlider from "../Components/CustomSlider";
import Sidebar from "../Components/SideBar/SideBar";
import { color } from "../Style/theme";

const wrapperBoxStyle = {
	display: "flex",
	flexDirection: "column",
};

const buttonsWrapperStyle = {
	display: "flex",
	flexDirection: "row",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
};

const leagueDataControlsStyle = {
	display: "flex",
	flexDirection: { xs: "column", md: "row" },
	width: "100%",
	gap: 2,
};

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	color: theme.palette.primary.main,
	"&.Mui-selected": {
		color: theme.palette.primary.main,
	},
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const generateChartOptions = (useLeagueData) => ({
	scales: {
		x: {
			title: {
				display: true,
				text: "Net resources utilized (mEUR)",
			},
			type: "logarithmic",
			position: "bottom",
			ticks: {
				callback: function (value) {
					return (value / 1000000).toFixed(1);
				},
				maxTicksLimit: 8,
				autoSkip: true,
				maxRotation: 45,
				minRotation: 45,
			},
		},
		y: {
			title: {
				display: true,
				text: "Competitiveness",
			},
		},
	},
	plugins: {
		legend: {
			display: true,
			labels: {
				filter: (legendItem) => legendItem.text,
				usePointStyle: true,
				padding: 20,
				boxHeight: 9,
				pointStyle: "circle",

				generateLabels: function (chart) {
					const datasets = chart.data.datasets;
					const lastIndex = datasets.length - 1;
					return datasets.map((dataset, i) => {
						const displayLine = i === lastIndex; // display line for Expected performance dataset
						return {
							text: dataset.label,
							fillStyle: dataset.backgroundColor,
							strokeStyle: dataset.backgroundColor,
							pointStyle: displayLine ? "line" : "circle", // Custom point style
							lineWidth: displayLine ? 4 : 1, // Custom point style
						};
					});
				},
			},
		},
		title: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					const isConfirmed = context.raw.calcMethod === "existing";
					return [
						`Season: ${context.raw.season}`,
						`Club: ${context.raw.club}`,
						`${!isConfirmed ? "Estimated " : ""}Net resources utilized:  ${(context.raw.x / 1000000).toFixed(2)} mEUR`,
						`Competitiveness: ${context.raw.y.toFixed(3)}`,
					];
				},
			},
		},
		watermark: {
			xAlign: "right",
		},
	},
	animation: {
		duration: 0,
	},
	responsive: true,
	maintainAspectRatio: false,
});

const processScatterData = (
	data,
	useLeagueData,
	sessionClubId,
	sliderValue,
	useFirstDivisonOnly,
	setDataArrayLen,
	setSliderValue,
	setAvailableClubs
) => {
	const scatterData = data.map((KPIF) => {
		const seasonYear = parseInt(KPIF.SeasonName, 10);
		const startYear = seasonYear - 1;
		const endYear = seasonYear + 1;

		// Format the years to only show the last two digits
		let seasonRange = `${startYear}-${endYear}`;

		if (!KPIF.SpringAutumn) {
			const startYearShort = startYear.toString().slice(-2);
			const endYearShort = endYear.toString().slice(-2);

			// Format the range as '19/20 - 21/22'
			seasonRange = `${startYearShort}/${seasonYear.toString().slice(-2)} - ${endYearShort}/${(endYear + 1).toString().slice(-2)}`;
		}

		return {
			x: KPIF.RollingFinancialSize,
			y: KPIF.RollingTotalCoefficient,
			season: seasonRange,
			club: KPIF.ClubName,
			clubId: KPIF.ClubId,
			division: KPIF.DivisionLevel,
			calcMethod: KPIF.Method,
		};
	});

	let filteredData;
	let regressionData;
	if (useLeagueData) {
		filteredData = scatterData;
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
		regressionData = filteredData;
	} else if (useFirstDivisonOnly) {
		const divisonData = scatterData.filter((KPIF) => KPIF.division < 2);
		regressionData = divisonData;
		setDataArrayLen(divisonData.length);
		setSliderValue((prevValue) =>
			prevValue !== undefined ? prevValue : Math.floor(divisonData.length / 2)
		); // Set slider default if undefined
		filteredData = divisonData.slice(0, sliderValue);
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
	} else {
		setDataArrayLen(scatterData.length);
		setSliderValue((prevValue) =>
			prevValue !== undefined ? prevValue : Math.floor(scatterData.length / 2)
		); // Set slider default if undefined
		regressionData = scatterData;
		filteredData = scatterData.slice(0, sliderValue);
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
	}

	// TODO: changes regression, the logarithmic regression is not defindined for non-positive integers
	const regressionResult = regression.logarithmic(
		regressionData.filter((point) => point.x > 0).map((point) => [point.x, point.y])
	);

	// make the regression line expand a bit beyond the points
	const maxX = Math.max(...filteredData.map((point) => point.x)) * 1.02;
	const minX =
		Math.min(...filteredData.filter((point) => point.x > 0).map((point) => point.x)) *
		0.98;

	const regressionLine = [
		{ x: minX, y: regressionResult.predict(minX)[1] },
		{ x: maxX, y: regressionResult.predict(maxX)[1] },
	];

	const sessionClubName = filteredData.find(
		(point) => point.clubId === sessionClubId
	)?.club;

	return {
		scatterData: filteredData,
		regressionLine,
		sessionClubName,
	};
};

const updateChartData = (
	selectedClubs,
	sessionClubId,
	scatterData,
	regressionLine,
	sessionClubName,
	leagueData
) => {
	// generates the same color based on a string
	const generateColorFromString = (str) => {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let color = "#";
		for (let i = 0; i < 3; i++) {
			const value = (hash >> (i * 8)) & 0xff;
			color += ("00" + value.toString(16)).substr(-2);
		}
		return color;
	};

	const specificClubData = scatterData.filter((point) => point.clubId === sessionClubId);

	const leagueScatterData = scatterData.filter(
		(point) =>
			leagueData.some((leaguePoint) => leaguePoint.ClubId === point.clubId) &&
			point.clubId !== sessionClubId &&
			!selectedClubs.some((club) => point.club === club)
	);

	const filteredScatterData = scatterData.filter(
		(point) => !selectedClubs.includes(point.club)
	);

	const filteredScatterDataWithOutLeague = filteredScatterData.filter(
		(point) =>
			!leagueData.some((leaguePoint) => leaguePoint.ClubId === point.clubId) &&
			point.clubId !== sessionClubId
	);

	const dataset = selectedClubs.map((club, index) => {
		const clubData = scatterData.filter((point) => point.club === club);
		return {
			type: "scatter",
			data: clubData,
			backgroundColor: generateColorFromString(club),
			borderColor: generateColorFromString(club),
			pointBackgroundColor: generateColorFromString(club),
			pointRadius: 6,
			pointStyle: clubData.map((point) =>
				point.calcMethod === "existing" ? "circle" : "triangle"
			),
		};
	});

	return {
		datasets: [
			...dataset,
			{
				type: "scatter",
				label: sessionClubName,
				data: specificClubData,
				backgroundColor: color.secondary.main,
				borderColor: color.secondary.main,
				pointBackgroundColor: color.secondary.main,
				pointRadius: 7,
				pointStyle: specificClubData.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},
			{
				type: "scatter",
				label: filteredScatterDataWithOutLeague.length !== 0 ? "Other Teams" : undefined,
				data: filteredScatterDataWithOutLeague,
				backgroundColor: color.warning.main,
				borderColor: color.warning.dark,
				pointBackgroundColor: color.warning.main,
				pointRadius: 5,
				pointStyle: filteredScatterDataWithOutLeague.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},
			{
				type: "scatter",
				label: "League Teams",
				data: leagueScatterData,
				backgroundColor: color.primary.main,
				borderColor: color.primary.light,
				pointRadius: 5,
				pointStyle: leagueScatterData.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},

			{
				type: "line",
				label: "Expected performance",
				data: regressionLine,
				fill: false,
				borderColor: color.error.main,
				backgroundColor: color.error.main,
				borderWidth: 3,
				pointRadius: 0,
			},
		],
	};
};

const PerformanceComparison = (props) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const [marks] = useState([
		{ value: 50, label: 50 },
		{ value: 100, label: 100 },
		{ value: 150, label: 150 },
		{ value: 200, label: 200 },
		{ value: 250, label: 250 },
		{ value: 300, label: 300 },
	]);
	const [sliderValue, setSliderValue] = useState();
	const [sliderLen, setSliderLen] = useState(0);
	const [useLeagueData, setUseLeagueData] = useState(true);
	const [tabValue, setTabValue] = useState("league");
	const [useFirstDivisonOnly, setUseFirstDivisonOnly] = useState(false);
	const [availableClubs, setAvailableClubs] = useState([]);
	const [selectedClubs, setSelectedClubs] = useState([]);

	useEffect(() => {
		const loadData = async () => {
			setUseLeagueData(tabValue === "league");

			const selectedData = useLeagueData ? props.leagueData : props.data;

			const { scatterData, regressionLine, sessionClubName } = processScatterData(
				selectedData,
				useLeagueData,
				props.sessionClubId,
				sliderValue,
				useFirstDivisonOnly,
				setSliderLen,
				setSliderValue,
				setAvailableClubs
			);
			const chartData = updateChartData(
				selectedClubs,
				props.sessionClubId,
				scatterData,
				regressionLine,
				sessionClubName,
				props.leagueData
			);
			setChartData(chartData);
		};

		loadData();
	}, [
		props.leagueData,
		props.data,
		props.sessionClubId,
		sliderValue,
		useFirstDivisonOnly,
		selectedClubs,
		tabValue,
		useLeagueData,
	]);

	const handleToggle = (event, newTabValue) => {
		setTabValue(newTabValue || "league");
	};

	return (
		<Box sx={wrapperBoxStyle}>
			<Box sx={buttonsWrapperStyle}>
				<ToggleButtonGroup
					value={tabValue}
					exclusive
					onChange={handleToggle}
					aria-label="Platform"
				>
					<CustomToggleButton value="league">
						<Toc style={{ marginRight: "8px" }} />
						League
					</CustomToggleButton>
					<CustomToggleButton value="global">
						<Public style={{ marginRight: "8px" }} /> Global
					</CustomToggleButton>
				</ToggleButtonGroup>
				<Sidebar
					availableClubs={availableClubs}
					selectedClubs={selectedClubs}
					setSelectedClubs={setSelectedClubs}
				/>
			</Box>
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "100%",
					maxWidth: { sx: "80vh", lg: "70vh" },
					aspectRatio: { xs: 3 / 4, md: 1 },
					alignSelf: "center",
				}}
			>
				<Scatter
					data={chartData}
					options={generateChartOptions(useLeagueData)}
					width={700}
					height={700}
				/>
			</Box>
			{!useLeagueData && (
				<Box sx={leagueDataControlsStyle}>
					<Box
						sx={{
							display: "flex",
							alignItems: { xs: "flex-start", md: "center" },
							flexDirection: {
								xs: "column",
								md: "row",
							},
							width: "100%",
						}}
					>
						<span
							style={{
								fontWeight: "bold",
								whiteSpace: "nowrap",
								marginRight: "16px",
							}}
						>
							Number of teams
						</span>
						<CustomSlider
							aria-label="Teams"
							onChange={(event, value) => setSliderValue(value)}
							value={sliderValue || Math.floor(sliderLen / 2)}
							valueLabelDisplay="auto"
							defaultValue={Math.floor(sliderLen / 2)}
							step={1}
							marks={marks}
							min={1}
							max={sliderLen}
						/>
					</Box>
					<FormControlLabel
						control={
							<Switch
								checked={useFirstDivisonOnly}
								onChange={() => setUseFirstDivisonOnly((prevState) => !prevState)}
							/>
						}
						label="First division teams only"
						disableTypography
						sx={{
							fontSize: "16px",
							whiteSpace: "nowrap",
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

export default PerformanceComparison;
