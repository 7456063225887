import React from "react";
import { Box, styled, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { impersonationStore } from "../State/ImpersonationStore";
import ClubSelect from "./Inputs/ClubSelect";

const Wrapper = styled(Box)(({ theme }) => ({
	width: 300,

	[theme.breakpoints.down("md")]: {
		width: 250,
	},

	[theme.breakpoints.down("sm")]: {
		width: 200,
	},
}));

const Impersonation = observer(() => {
	const theme = useTheme();

	if (impersonationStore.error) {
		return <Typography variant="body2">{impersonationStore.error}</Typography>;
	}

	return (
		<Wrapper>
			<ClubSelect
				id="impersonation"
				loadingOptions={impersonationStore.isLoading}
				options={impersonationStore.dropdownOptions}
				label="Club"
				onChange={impersonationStore.handleChange}
				value={impersonationStore.selectedOption?.ClubId}
				sx={{
					[theme.breakpoints.down("sm")]: {
						width: 200,
						"& .MuiOutlinedInput-root": {
							padding: "0 5px",
						},
					},
				}}
			/>
		</Wrapper>
	);
});

export default Impersonation;
