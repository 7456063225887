import React, { useEffect, useState } from "react";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import "../Datapage/Dashboard.css";
import "./MatchReportDashboard.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../Util/routes";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	fontSize: "20px",
	fontFamily: "Montserrat, sans-serif",
	color: "#23374C",
	"&.Mui-selected": {
		color: "#23374C",
	},
	"&:hover": {
		color: "#23374C",
	},
}));

const MatchReportDashboard = ({ data }) => {
	const [games, setGames] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const matchesData = Array.isArray(data) ? data : [];

		const updatedGames = matchesData
			.map((match) => ({
				title: match?.Status || "",
				team: `${match?.HomeClubName} - ${match?.AwayClubName} `,
				awayClubImage: match?.AwayClubImageUrl,
				homeClubImage: match?.HomeClubImageUrl,
				homeClubId: match?.HomeClubId,
				awayClubId: match?.AwayClubId,
				dateutc: new Date(match?.DateUtc),
				status: match?.Status,
				matchId: match?.MatchId,
			}))
			.sort((a, b) => b.dateutc - a.dateutc); // Sort descending by dateutc

		setGames(updatedGames);
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Set time to the start of the day

		const fixtureIndex = updatedGames.findIndex((game, idx, arr) => {
			const match = arr[arr.length - 1 - idx];
			const isFixture = match.status === "Fixture";
			const isFutureDate = match.dateutc > today;

			return isFixture && isFutureDate;
		});

		// This is where we set inital matchId search param!
		if (!searchParams.get("matchId")) {
			const index = fixtureIndex !== -1 ? updatedGames.length - 1 - fixtureIndex : 0;
			setCurrentIndex(index);
			setSearchParams({ matchId: updatedGames[index].matchId });
		} else {
			const matchIdString = searchParams.get("matchId");
			const matchIdIndex = updatedGames.findIndex(
				(match) => match.matchId === Number(matchIdString)
			);
			setCurrentIndex(matchIdIndex);
		}
	}, [data]);

	const handleNext = () => {
		if (currentIndex > 0) {
			const newIndex = currentIndex - 1;
			setCurrentIndex(newIndex);
			setSearchParams({ matchId: games[newIndex]?.matchId });
		}
	};

	const handlePrev = () => {
		if (currentIndex < games.length - 1) {
			const newIndex = currentIndex + 1;
			setCurrentIndex(newIndex);
			setSearchParams({ matchId: games[newIndex]?.matchId });
		}
	};

	return (
		<div className="dashboard-background">
			<div
				style={{
					padding: "30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ToggleButtonGroup
					color="primary"
					value={location.pathname}
					onChange={(event, newValue) => {
						if (newValue) {
							navigate(newValue + location.search);
						}
					}}
					size="large"
					exclusive
					aria-label="Platform"
				>
					<CustomToggleButton value={routes.am.club.matchReport.pre}>
						Pre-Match
					</CustomToggleButton>
					<CustomToggleButton value={routes.am.club.matchReport.post}>
						Post-Match
					</CustomToggleButton>
				</ToggleButtonGroup>
			</div>
			<div className="dashboard-KPI-carousel">
				<div
					className={`dashboard-KPI-card prev-card ${currentIndex >= games.length - 1 ? "disabled" : ""}`}
				>
					{currentIndex < games.length - 1 ? (
						<>
							<div className="dashboard-KPI-card-title">
								<span>{games[currentIndex + 1]?.title}</span>
							</div>
							<div className="dashboard-KPI-card-images">
								<img
									src={games[currentIndex + 1]?.homeClubImage}
									alt="Home Club"
									className="club-logo"
								/>
								<img
									src={games[currentIndex + 1]?.awayClubImage}
									alt="Away Club"
									className="club-logo"
								/>
							</div>
							<div className="dashboard-KPI-card-value">
								{games[currentIndex + 1]?.team}
							</div>
						</>
					) : (
						// Render an empty space if the card is disabled
						<div
							className="empty-content"
							style={{ visibility: "hidden", height: "300px", width: "400px" }}
						></div>
					)}
				</div>

				{/* Previous Button */}
				<div className="navigation-buttons">
					{!(games.length === currentIndex + 1) && (
						<button className="prev-btn" onClick={handlePrev}>
							<ArrowCircleLeft />
						</button>
					)}
				</div>

				{/* Current Game */}
				<div className="dashboard-KPI-card current-card">
					<div className="dashboard-KPI-card-title">
						<span>{games[currentIndex]?.title}</span>
					</div>
					<div className="dashboard-KPI-card-images">
						<img
							src={games[currentIndex]?.homeClubImage}
							alt="Home Club"
							className="club-logo"
						/>
						<img
							src={games[currentIndex]?.awayClubImage}
							alt="Away Club"
							className="club-logo"
						/>
					</div>
					<div className="dashboard-KPI-card-value">{games[currentIndex]?.team}</div>
				</div>

				{/* Next Button */}
				<div className="navigation-buttons">
					{!(currentIndex === 0) && (
						<button className="next-btn" onClick={handleNext}>
							<ArrowCircleRight />
						</button>
					)}
				</div>

				{/* Next Game (Now moves backward) */}
				<div
					className={`dashboard-KPI-card next-card ${currentIndex === 0 ? "disabled" : ""}`}
				>
					{currentIndex > 0 ? (
						<>
							<div className="dashboard-KPI-card-title">
								<span>{games[currentIndex - 1]?.title}</span>
							</div>
							<div className="dashboard-KPI-card-images">
								<img
									src={games[currentIndex - 1]?.homeClubImage}
									alt="Home Club"
									className="club-logo"
								/>
								<img
									src={games[currentIndex - 1]?.awayClubImage}
									alt="Away Club"
									className="club-logo"
								/>
							</div>
							<div className="dashboard-KPI-card-value">
								{games[currentIndex - 1]?.team}
							</div>
						</>
					) : (
						// Render an empty space if the card is disabled
						<div
							className="empty-content"
							style={{ visibility: "hidden", height: "300px", width: "400px" }}
						></div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MatchReportDashboard;
