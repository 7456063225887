import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CircularProgress,
	Typography,
} from "@mui/material";
import theme from "../Style/theme";
import { auth0Service } from "../Util/Auth0Client";
import { chargebeeCheckout, getCustomerSubscription, ItemIcon } from "./utilFunctions";

const Addons = () => {
	const [addons, setAddons] = useState([]);
	const [subscriptionData, setSubscriptionData] = useState([]);

	const customerSubscription = async () => {
		const customerSubscription = await getCustomerSubscription();
		if (!customerSubscription) return;
		setSubscriptionData(customerSubscription);
	};

	const fetchAddons = async () => {
		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };

			const response = await fetch("/api/chargebee/addons", {
				method: "GET",
				headers: header,
				credentials: "include",
			});
			const addons = await response.json();
			const sortedAddons = addons.sort((a, b) => {
				return a.Item.metadata.sortorder - b.Item.metadata.sortorder;
			});
			setAddons(sortedAddons);
		} catch (error) {
			console.log("Something went wrong", error);
		}
	};
	useEffect(() => {
		customerSubscription();
		fetchAddons();
	}, []);

	return (
		<Box sx={{ textAlign: "center" }}>
			<Typography variant="h3" sx={{ marginBottom: 4 }}>
				AVAILABLE ADDONS
			</Typography>
			{addons.length > 0 ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: 5,
							justifyContent: "center",
						}}
					>
						{addons.map((addon, index) => (
							<Box
								key={index}
								sx={{
									width: "100%",
									height: "auto",
									maxWidth: "300px",
									minWidth: "300px",
									backgroundColor:
										addon.Item.metadata.recommended === "true"
											? theme.palette.primary.light
											: theme.palette.primary.main,
									borderRadius: 3,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "flex-start",
									marginBottom: "2vh",
								}}
							>
								<Typography
									sx={{
										fontSize: 18,
										color: "white",
										textAlign: "center",
										width: "100%",
										m: 0,
										fontWeight: 600,
									}}
								>
									{addon.Item.metadata.recommended === "true" ? "Recommended" : "Choose"}
								</Typography>
								<Card
									sx={{
										borderRadius: 3,
										width: "98%",
										backgroundColor: "#F3F5F1",
										flex: 1,
										mb: "4px",
									}}
								>
									<CardActionArea
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
											gap: "5px",
										}}
										onClick={() => chargebeeCheckout(subscriptionData.id, addon.Item.id)}
									>
										<CardContent
											sx={{
												width: "100%",
												backgroundColor: "#F3F5F1",
												textAlign: "center",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											{ItemIcon({ item: addon, size: 45 })}
										</CardContent>
										<CardContent
											sx={{
												width: "100%",
												textAlign: "center",
												padding: "8px 0",
												marginTop: -3,
												marginBottom: -3,
											}}
										>
											<Typography variant="h4" component="div" fontWeight={450}>
												{addon.Item.external_name}
											</Typography>
										</CardContent>
										<CardContent sx={{ textAlign: "center" }}>
											<CardContent
												sx={{
													display: "flex",
													justifyContent: "center",
													padding: 0,
												}}
											>
												{[...addon.Features]
													.map((feature) => feature.replace(" - Available", ""))
													.sort((a, b) => a.localeCompare(b))
													.map((feature, idx) => (
														<Typography
															key={idx}
															variant="body2"
															sx={{ fontSize: 16, textAlign: "left" }}
														>
															<li>{feature}</li>
														</Typography>
													))}
											</CardContent>
											{addon.Prices.map((price, idx) => (
												<CardContent
													sx={{
														display: "flex",
														justifyContent: "center",
													}}
													key={idx}
												>
													<Typography sx={{ fontSize: "2rem", fontWeight: 700 }}>
														{price.currency_code === "EUR" ? "€" : price.currency_code}
														{price.price / 100} / {price.period_unit}
													</Typography>
												</CardContent>
											))}
										</CardContent>
									</CardActionArea>
								</Card>
							</Box>
						))}
					</Box>
				</div>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
};

export default Addons;
