import { Box } from "@mui/material";

const numberStyle = {
	textAlign: "center",
	fontSize: "56px",
	fontWeight: "bold",
};

const labelStyle = {
	textAlign: "center",
	fontSize: "18px",
	width: "100%",
};

const Rank = ({ ranking }) => {
	return (
		<Box>
			<Box sx={numberStyle}>{ranking}</Box>
			<Box sx={labelStyle}>Rank</Box>
		</Box>
	);
};

export default Rank;
