import React, { useEffect } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { impersonationStore } from "../State/ImpersonationStore";
import { profileStore } from "../State/ProfileStore";
import { userEntitlementStore } from "../State/UserEntitlementStore";
import { routes } from "../Util/routes";
import TokenVerifier from "./TokenVerifier";

const AuthProviderWithHistory = ({ children }) => {
	const navigate = useNavigate();

	const onRedirectCallback = async (appState) => {
		try {
			await Promise.all([
				impersonationStore.loadDropDownOptions(),
				profileStore.init(),
				userEntitlementStore.GetUserEntitlements(),
			]);

			navigate(appState?.returnTo || window.location.pathname);
		} catch (error) {
			console.error("Error during onRedirectCallback:", error);
		}
	};

	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH_DOMAIN}
			clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin + routes.am.profile,
				audience: process.env.REACT_APP_SERVER,
				scope: "openid profile email offline_access",
			}}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			onRedirectCallback={onRedirectCallback}
		>
			{children}
			<TokenVerifier />
		</Auth0Provider>
	);
};

export default AuthProviderWithHistory;
