import React, { useMemo } from "react";
import "../Datapage/Datapage.css";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Chart } from "chart.js";
import { getGradientBg } from "../Style/chartStyleUtils";
import { hexToRgba } from "../Style/styleUtils";
import { color } from "../Style/theme";

const PossessionChart = ({ firstClubData }) => {
	const labels = useMemo(() => ["0", "15", "30", "HT", "60", "75", "FT"], []);

	const getTooltipText = (label) => {
		switch (label) {
			case labels[0]:
				return "0-15";
			case labels[1]:
				return "16-30";
			case labels[2]:
				return "31-45+";
			case labels[3]:
				return "46-60";
			case labels[4]:
				return "61-75";
			case labels[5]:
				return "76-90+";
			case labels[6]:
				return "Full time";
			default:
				return label;
		}
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				min: 0,
				max: 100,
				beginAtZero: true,
				title: {
					display: true,
					text: "Percent (%)",
				},
			},
			x: {
				title: {
					display: true,
					text: "Minutes",
				},
			},
		},
		plugins: {
			legend: {
				labels: {
					generateLabels: (chart) => {
						// Call the original function to get default labels
						const labels = Chart.defaults.plugins.legend.labels.generateLabels.call(
							this,
							chart
						);

						return labels.map((label, index) => ({
							...label,
							lineWidth: 0, // Remove border by setting lineWidth to 0
							fillStyle: chart.data.datasets[index].borderColor, // Use dataset's borderColor as fill color
						}));
					},
				},
			},
			tooltip: {
				callbacks: {
					title: function (context) {
						let label = context[0].label || "";
						return getTooltipText(label);
					},
					label: function (context) {
						const label = context.dataset.label || "";
						const value = context.formattedValue;
						return `${label}: ${value} %`;
					},

					labelColor: (context) => {
						const dataset = context.dataset;
						return {
							backgroundColor: dataset.borderColor,
						};
					},
				},
			},
		},
	};

	const chartData = useMemo(() => {
		const dataSets = [
			{
				label: "Possession",
				data: [
					firstClubData?.Possession1_15,
					firstClubData?.Possession16_30,
					firstClubData?.Possession31_45,
					firstClubData?.Possession46_60,
					firstClubData?.Possession61_75,
					firstClubData?.Possession76_90,
					firstClubData?.Possession76_90,
				],
				borderColor: color.secondary.main,
				tension: 0,
				stepped: "before",
				fill: true,
				backgroundColor: getGradientBg(hexToRgba(color.secondary.main, 0.5)),
			},
		];

		return {
			labels: labels,
			datasets: dataSets,
		};
	}, [firstClubData, labels]);

	return (
		<div className="chart-section">
			<div
				style={{ width: "100%", margin: "auto", height: "400px", position: "relative" }}
			>
				<Line options={chartOptions} data={chartData} width={600} height={500} />
			</div>
		</div>
	);
};

export default PossessionChart;
