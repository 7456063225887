import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { toJS } from "mobx";
import { Pie } from "react-chartjs-2";
import CustomTable from "../Components/Table";
import "../Style/am_style.css";
import "./Datapage.css";
import "./Dashboard.css";
import { Circle } from "@mui/icons-material";
import { color } from "../Style/theme";

const wrapperStyle = {
	display: "grid",
	justifyItems: "center",
	gridTemplateRows: {
		xs: "max-content 1fr",
		xl: "1fr ",
	},
	gridTemplateColumns: {
		xs: "1fr",
		xl: "49% 49%",
	},

	gap: {
		xs: 4,
		xl: 0,
	},
};

const TransferKPIRisk = (props) => {
	const [playerData, setPlayerData] = useState([]);
	const [transferKPIRiskLevel, setTransferKPIRiskLevel] = useState([]);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});

	const [colorsInChart, setColorsInChart] = useState([]);

	// Watch colorsInChart and update Circle on each row accordingly
	const tableColumns = useMemo(() => {
		return [
			{
				field: "PlayerName",
				headerName: "Player",
				customContent: (field, row, rowIndex) => {
					return (
						<span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
							<Circle sx={{ color: colorsInChart[rowIndex] ?? "white" }} /> {field}
						</span>
					);
				},
			},
			{
				field: "TransferKPI",
				headerName: "Transfer KPI",
				customStyle: { textAlign: "center" },
			},
			{
				field: "PartOfTeamKPI",
				headerName: "Team Share",
				customContent: (field) => {
					return field + "%";
				},
				customStyle: { textAlign: "center" },
			},
		];
	}, [colorsInChart]);

	const chartRef = useRef(null);

	const options = {
		responsive: true,
		layout: {
			padding: 10,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (tooltipItem) {
						return `${tooltipItem.raw}%`;
					},
				},
			},
			watermark: {
				logoStyle: "vertical",
				xAlign: "center",
				yAlign: "center",
			},
		},
	};

	const generateRandomColor = () => {
		const r = Math.floor(Math.random() * 256);
		const g = Math.floor(Math.random() * 256);
		const b = Math.floor(Math.random() * 256);
		return `rgba(${r},${g},${b},0.5)`;
	};

	const generateTransferRiskLevel = (data) => {
		const numberOfRows = data?.length;
		let transferLevel;

		if (numberOfRows > 4) {
			transferLevel = "Low";
		} else if (numberOfRows >= 3 && numberOfRows <= 4) {
			transferLevel = "Medium";
		} else if (numberOfRows < 3 && numberOfRows !== 0) {
			transferLevel = "High";
		} else {
			transferLevel = "Not available";
		}

		return transferLevel;
	};

	useEffect(() => {
		const loadData = (propdata, comparisonData) => {
			let data = toJS(propdata);
			const predefinedColors = [
				color.secondary.main,
				color.warning.main,
				color.error.main,
				color.secondary.light,
				color.warning.light,
				color.error.light,
				color.primary.light,
				color.mono.first,
				color.mono.second,
				color.mono.third,
				color.mono.fourth,
			];

			setTransferKPIRiskLevel(generateTransferRiskLevel(data));

			if (!data) return;

			var filteredData = comparisonData.filter((item) => item.SeasonName);
			const sortedCdata = filteredData.sort((a, b) =>
				b.SeasonName.localeCompare(a.SeasonName)
			);

			const lastSeasonTeamKPI = sortedCdata[0]?.TeamTransferKPI;
			const sortedData = toJS(data).sort((a, b) => b.TransferKPI - a.TransferKPI);
			const totalKPIRiskValue = data.reduce((acc, d) => acc + d.TransferKPI, 0);

			sortedData.forEach((d) => {
				if (typeof d.TransferKPI === "number") {
					d.TransferKPI = parseFloat(d.TransferKPI.toFixed(2));
				}
			});

			const partOfTeamKPI = sortedData.map((d) =>
				((d.TransferKPI / lastSeasonTeamKPI) * 100).toFixed(1)
			);

			sortedData.forEach((d, i) => {
				d.PartOfTeamKPI = partOfTeamKPI[i];
			});

			const restOfTheTeamPercentage = (
				((lastSeasonTeamKPI - totalKPIRiskValue) / lastSeasonTeamKPI) *
				100
			).toFixed(1);

			const restOfTheTeamRow = {
				PlayerName: "Rest of the team",
				TransferKPI: "-",
				PartOfTeamKPI: restOfTheTeamPercentage,
			};

			sortedData.push(restOfTheTeamRow);
			setPlayerData(sortedData);

			const playerDataLength = sortedData.length - 1; // do not count restOfTheTeamRow since it should have same color always (primary)

			const requiredColors =
				playerDataLength > predefinedColors.length
					? [
							...predefinedColors,
							...Array(playerDataLength - predefinedColors.length)
								.fill()
								.map(generateRandomColor),
						]
					: predefinedColors.slice(0, playerDataLength);

			requiredColors.push(color.primary.main); // add color for "Rest of the team"
			setColorsInChart(requiredColors);

			setChartData({
				labels: sortedData.map((d) => d.PlayerName),
				datasets: [
					{
						data: sortedData.map((d) => d.PartOfTeamKPI),
						backgroundColor: requiredColors,
						borderColor: requiredColors,
						borderWidth: 0,
					},
				],
			});
		};

		loadData(props.data, props.comparisonData);
	}, [props.data, props.comparisonData]);

	if (!props.data || props.data.some((item) => !item.TransferKPI)) {
		return (
			<div
				className="dashboard-KPI-card"
				style={{ pointerEvents: "none", margin: "0 auto" }}
			>
				<div className="beta-tag">Beta</div>
				<div className="dashboard-KPI-card-title">Transfer KPI Risk</div>
				<div className="dashboard-KPI-card-value">
					<span
						style={{
							color:
								transferKPIRiskLevel === "High"
									? "red"
									: transferKPIRiskLevel === "Medium"
										? "orange"
										: "green",
						}}
					>
						{transferKPIRiskLevel ? transferKPIRiskLevel : "Not available"}
					</span>
				</div>
			</div>
		);
	}

	return (
		<Box sx={wrapperStyle}>
			<Box
				sx={{
					minWidth: "40vh",
					width: "100%",
					height: "100%",
					maxWidth: "35vh",
					aspectRatio: 1,
					alignSelf: "center",
					position: "relative",
				}}
			>
				<Pie data={chartData} options={options} width={200} height={200} ref={chartRef} />
			</Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CustomTable tableData={playerData} tableColumns={tableColumns} />
			</Box>
		</Box>
	);
};
export default TransferKPIRisk;
