import React from "react";
import { styled, Typography } from "@mui/material";
import caesarImg from "../Assets/Images/caesar.jpg";
import carlImg from "../Assets/Images/carl.jpg";
import davidImg from "../Assets/Images/david.png";
import felixImg from "../Assets/Images/felix.png";
import lukasImg from "../Assets/Images/lukas.jpg";
import nilsImg from "../Assets/Images/nils.png";
import tomasImg from "../Assets/Images/tomas.jpg";
import vilmerImg from "../Assets/Images/vilmer.png";
import emptyProfile from "../Logos/empty_profile.png";
import theme, { color } from "../Style/theme";
import { InnerWrapper, LandingPageElement } from "./components";

const TeamList = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-evenly",
	flexWrap: "wrap",
	padding: theme.spacing(4, 0),
}));

const TeamMember = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "50%",
	marginBottom: theme.spacing(4),

	[theme.breakpoints.up("sm")]: {
		width: "33%",
		marginBottom: theme.spacing(6),
	},
	[theme.breakpoints.up("md")]: {
		marginBottom: theme.spacing(8),
	},
	[theme.breakpoints.up("lg")]: {
		width: "25%",
		marginBottom: theme.spacing(10),
	},
	[theme.breakpoints.up("xl")]: {
		width: "20%",
	},
}));

const Picture = styled("img")(({ theme }) => ({
	width: "100px",
	height: "100px",
	borderRadius: "50%",
	objectFit: "cover",

	[theme.breakpoints.up("sm")]: {
		width: "120px",
		height: "120px",
	},
	[theme.breakpoints.up("md")]: {
		width: "150px",
		height: "150px",
	},
}));

const Role = styled(Typography)(({ theme }) => ({
	color: theme.palette.background.main,
	fontWeight: "bold",
	marginBottom: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
	color: theme.palette.background.main,
}));

function Team() {
	const teamMembers = [
		{ role: "Founder", name: "Caesar Gezelius", img: caesarImg },
		{ role: "Founder", name: "Lukas Berglund", img: lukasImg },
		{ role: "Development", name: "Carl Magnusson", img: carlImg },
		{ role: "Analysis", name: "David Orre", img: davidImg },
		{ role: "Development", name: "Felix Fungula", img: felixImg },
		{ role: "Development", name: "Nils Forsgren", img: nilsImg },
		{ role: "Development", name: "Hanna Mellin", img: emptyProfile },
		{ role: "Development", name: "Tomas Weldetinsae", img: tomasImg },
		{ role: "Analysis", name: "Vilmer Olin", img: vilmerImg },
	];

	return (
		<LandingPageElement name="team">
			<InnerWrapper sx={{ marginTop: theme.spacing(2) }}>
				<Typography variant="h2" sx={{ color: color.background.main }}>
					Meet Our Team
				</Typography>
				<TeamList>
					{teamMembers.map((member, index) => (
						<TeamMember key={index}>
							<Picture src={member.img} alt={member.name} />
							<Role component="h3">{member.role}</Role>
							<Name variant="body2">{member.name}</Name>
						</TeamMember>
					))}
				</TeamList>
			</InnerWrapper>
		</LandingPageElement>
	);
}

export default Team;
