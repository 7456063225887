import React from "react";
import "../Datapage/Datapage.css";
import BarChart from "./FlanksBarChart";

const FlanksChart = ({ firstClubData, leagueAvg }) => {
	const flankData = {
		left: [
			firstClubData?.LeftFlankAttacks,
			leagueAvg?.AvgLeftFlankAttacks,
			firstClubData?.CrossesFromLeftFlank,
			leagueAvg?.AvgCrossesFromLeftFlank,
		],
		right: [
			firstClubData?.RightFlankAttacks,
			leagueAvg?.AvgRightFlankAttacks,
			firstClubData?.CrossesFromRightFlank,
			leagueAvg?.AvgCrossesFromRightFlank,
		],
	};

	const successfulData = {
		left: [null, null, firstClubData?.CrossesFromLeftFlankSuccessful, null], // using 'null' to appear in the "Crosses" category only.
		right: [null, null, firstClubData?.CrossesFromRightFlankSuccessful, null],
	};

	const labelsFlank = ["Flank Attacks", "Avg Attacks", "Crosses", "Avg Crosses"];

	const flankxGData = {
		left: [firstClubData?.LeftFlankXg, leagueAvg?.AvgLeftFlankxG],
		right: [firstClubData?.RightFlankXg, leagueAvg?.AvgRightFlankxG],
	};
	const labelsxG = ["Flank xG", "Average in League"];

	return (
		<>
			<h2>{firstClubData?.ClubGroup}</h2>

			<div
				className="chart-section"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				<div style={{ flex: "1 1 35%" }}>
					<h2>Flank</h2>
					<BarChart
						data={flankData}
						successfulData={successfulData}
						labels={labelsFlank}
					/>
				</div>
				<div style={{ flex: "1 1 35%" }}>
					<h2>Flank xG</h2>
					<BarChart data={flankxGData} labels={labelsxG} />
				</div>
			</div>
		</>
	);
};

export default FlanksChart;
