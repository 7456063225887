import React, { useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Scatter } from "react-chartjs-2";
import { hexToRgba } from "../Style/styleUtils";
import { color } from "../Style/theme";

const wrapperBoxStyle = {
	display: "flex",
	flexDirection: "column",
};

const generateChartOptions = (
	clubOfInterestId,
	clubOfInterestX,
	clubOfInterestY,
	scale
) => ({
	scales: {
		x: {
			ticks: {
				display: false,
			},
			beginAtZero: true,
			title: {
				display: true,
			},
			position: "bottom",
			min: -scale,
			max: scale,
		},
		y: {
			ticks: {
				display: false,
			},
			beginAtZero: true,
			title: {
				display: true,
			},
			min: -scale,
			max: scale,
		},
	},
	plugins: {
		legend: {
			display: true,
		},
		title: {
			display: false,
		},
		tooltip: {
			filter: function (tooltipItem) {
				const dataPoint = tooltipItem.raw;
				const clubId = dataPoint.clubId;
				const x = dataPoint.x;
				const y = dataPoint.y;
				const epsilon = 0.0001;
				return (
					clubId === clubOfInterestId &&
					Math.abs(x - clubOfInterestX) < epsilon &&
					Math.abs(y - clubOfInterestY) < epsilon
				);
			},
			callbacks: {
				label: function (context) {
					return [`Club: ${context.raw.club}`, `Style of Play: ${context.raw.group}`];
				},
			},
		},
		datalabels: {
			display: function (context) {
				const dataPoint = context.dataset.data[context.dataIndex];
				const clubId = dataPoint.clubId;
				const x = dataPoint.x;
				const y = dataPoint.y;
				const epsilon = 0.0001;
				return (
					clubId === clubOfInterestId &&
					Math.abs(x - clubOfInterestX) < epsilon &&
					Math.abs(y - clubOfInterestY) < epsilon
				);
			},
			formatter: function (value, context) {
				return context.dataset.data[context.dataIndex].club;
			},
			align: "top",
			color: "black",
			font: {
				weight: 700,
			},
			padding: {
				bottom: 10,
			},
		},
	},
	responsive: true,
	maintainAspectRatio: false,
});

const processScatterData = (data = []) => {
	const scatterData = data.map((SOP) => {
		return {
			x: SOP?.X,
			y: SOP?.Y,
			group: SOP?.C,
			club: SOP?.ClubName,
			clubId: SOP?.ClubId,
		};
	});
	return scatterData;
};

const updateChartData = ({ scatterData = [], clubOfInterestScatterData = [] }) => {
	// Get unique groups from scatterData
	const groups = [...new Set(scatterData.map((point) => point.group))];

	const colors = [
		color.primary.light,
		color.mono.fourth,
		color.warning.main,
		color.error.main,
		color.secondary.main,
	];

	// Create datasets for each group
	const groupDatasets = groups.map((group, index) => {
		const groupData = scatterData.filter((point) => point.group === group);

		return {
			type: "scatter",
			label: `Group ${group}`,
			data: groupData,
			backgroundColor: colors[group],
			borderColor: colors[group],
			pointBackgroundColor: colors[group],
			pointRadius: 5,
		};
	});

	const sessionClubDataset = {
		type: "scatter",
		label: clubOfInterestScatterData[0]?.club || "Club of Interest",
		data: clubOfInterestScatterData,
		backgroundColor: colors[clubOfInterestScatterData[0].group],
		borderColor: "black",
		pointBackgroundColor: hexToRgba(colors[clubOfInterestScatterData[0].group], 0.5),
		pointStyle: "rectRot",
		pointRadius: 12,
		pointHoverRadius: 14,
	};

	return {
		datasets: [sessionClubDataset, ...groupDatasets],
	};
};

const StyleOfPlay = ({ pcaResults = [], styleOfPlay = [] }) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const [clubOfInterestId, setClubOfInterestId] = useState(0);
	const [clubOfInterestX, setClubOfInterestX] = useState(0);
	const [clubOfInterestY, setClubOfInterestY] = useState(0);
	const [scale, setScale] = useState(1);

	const chartOptions = useMemo(
		() => generateChartOptions(clubOfInterestId, clubOfInterestX, clubOfInterestY, scale),
		[clubOfInterestId, clubOfInterestX, clubOfInterestY]
	);

	useEffect(() => {
		const loadData = () => {
			const scatterData = processScatterData(styleOfPlay);
			const clubOfInterestScatterData = processScatterData(pcaResults);

			const chartData = updateChartData({
				scatterData,
				clubOfInterestScatterData,
			});
			setChartData(chartData);

			setScale(
				Math.max(
					Math.max(...scatterData.map((point) => Math.abs(point.x))),
					Math.max(...scatterData.map((point) => Math.abs(point.y)))
				) + 0.15
			);

			const clubId = clubOfInterestScatterData[0]?.clubId || null;
			const x = clubOfInterestScatterData[0]?.x || null;
			const y = clubOfInterestScatterData[0]?.y || null;
			setClubOfInterestId(clubId);
			setClubOfInterestX(x);
			setClubOfInterestY(y);
		};
		if (styleOfPlay.length > 0 && pcaResults.length > 0) {
			loadData();
		}
	}, [pcaResults, styleOfPlay]);

	return (
		<Box sx={wrapperBoxStyle}>
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "100%",
					maxWidth: { sx: "80vh", lg: "70vh" },
					aspectRatio: { xs: 3 / 4, md: 1 },
					alignSelf: "center",
				}}
			>
				<Scatter
					data={chartData}
					options={chartOptions}
					plugins={[ChartDataLabels]}
					width={700}
					height={700}
				/>
			</Box>
		</Box>
	);
};

export default StyleOfPlay;
