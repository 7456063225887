import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Edit, FlagRounded } from "@mui/icons-material";
import { Avatar, Card, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import profile from "../Logos/empty_profile.png";
import { profileStore } from "../State/ProfileStore";
import { maxOneDecimal } from "../Util/textFormatting";
import AcademyPlayerIcon from "./AcademyPlayerIcon";

const cardStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	aspectRatio: { xs: 1 / 1.3, md: 1 },
	textAlign: "center",
	flexDirection: "column",
	p: { xs: 1, sm: 2 },
	backgroundColor: "background.light",
	position: "relative",
};

const selectedCardStyle = {
	backgroundColor: "primary.main",
	color: "primary.contrast",
};

const buttonStyle = {
	position: "absolute",
	top: 2,
	right: 2,
};

const PlayerCard = ({
	player,
	onClick,
	isSelected,
	onEditClick,
	onReportClick,
	highlightedValue,
}) => {
	const { user } = useAuth0();

	const handleButtonClick = (e) => {
		e.stopPropagation();
		if (profileStore.isAdministrator()) {
			onEditClick();
		} else {
			onReportClick();
		}
	};

	const subtitle = useMemo(() => {
		switch (highlightedValue) {
			case "Name":
				return player.PositionName ?? "-";
			case "DateOfBirth":
			case "ContractExpiration":
				return player[highlightedValue]
					? dayjs(player[highlightedValue]).format("YYYY-MM-DD")
					: "-";
			case "TransferKPI":
				return player[highlightedValue] !== null
					? maxOneDecimal(player[highlightedValue])
					: "-";
			case "Height":
				return player[highlightedValue] ? player[highlightedValue] + " cm" : "-";
			case "Weight":
				return player[highlightedValue] ? player[highlightedValue] + " kg" : "-";
			default:
				return player[highlightedValue] ?? "-";
		}
	}, [highlightedValue, player]);
	return (
		<Card
			sx={isSelected ? { ...cardStyle, ...selectedCardStyle } : cardStyle}
			onClick={onClick}
		>
			<IconButton sx={buttonStyle} onClick={(e) => handleButtonClick(e)}>
				{profileStore.isAdministrator() ? (
					<Edit fontSize="small" sx={{ color: "primary.light" }} />
				) : (
					<FlagRounded fontSize="small" sx={{ color: "primary.light" }} />
				)}
			</IconButton>
			<Avatar
				alt={player.ImageUrl ? player.Name : "Empty profile image"}
				src={player.ImageUrl ?? profile}
			/>
			{player.AcademyClubIds?.includes(player.ClubId) && (
				<AcademyPlayerIcon
					sx={{
						position: "absolute",
						top: 8,
						left: 8,
					}}
				/>
			)}

			<Typography sx={isSelected ? { color: "primary.contrast" } : {}} variant="h4">
				{`${player.FirstName} ${player.LastName}`}
			</Typography>
			<Typography variant="body2">{subtitle}</Typography>
		</Card>
	);
};

export default PlayerCard;
