import { Button, DialogActions, DialogContent } from "@mui/material";
import ModalWithTitle from "./ModalWithTitle";

const OkCancelModal = ({
	open,
	children,
	title,
	onOk,
	onClose,
	okText = "Ok",
	maxWidth,
}) => {
	const handleClose = () => {
		onClose();
	};

	const handleOk = () => {
		onOk();
	};

	return (
		<ModalWithTitle
			title={title}
			open={open}
			onClose={handleClose}
			maxWidth={maxWidth}
			disableContentPadding
		>
			<DialogContent>{children}</DialogContent>
			<DialogActions sx={{ px: 2, pb: 2 }}>
				<Button onClick={handleClose} variant="outlined">
					Cancel
				</Button>
				<Button onClick={handleOk} autoFocus variant="contained">
					{okText}
				</Button>
			</DialogActions>
		</ModalWithTitle>
	);
};

export default OkCancelModal;
