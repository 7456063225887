import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, styled, Typography } from "@mui/material";
import ModalWithTitle from "../Components/Modal/ModalWithTitle";
import { capitalizeString } from "../Util/textFormatting";
import { parseSubscriptionItems } from "./utilFunctions";

const CustomButton = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	"&:hover": {
		backgroundColor: theme.palette.error.main,
	},
}));

const CancelSubscription = ({
	userSubscription,
	buttonText,
	apiEndpoint,
	httpMethod,
}) => {
	const [open, setOpen] = useState(false);
	const [cancellationDate, setCancellationDate] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [itemName, setItemName] = useState([]);
	const { getAccessTokenSilently } = useAuth0();

	const parseItemName = (userSubscription) => {
		if (!userSubscription) return;
		const planItem = userSubscription.subscription_items.find(
			(item) => item.item_type === "plan"
		);

		const nameSet = parseSubscriptionItems([planItem]);
		const [name] = [...nameSet];
		const finalName = name.split("-").map(capitalizeString).join(" ");
		return finalName;
	};

	useEffect(() => {
		if (userSubscription) {
			const trialEnd = userSubscription.trial_end * 1000;
			const currentTimestamp = Date.now();
			let CancelDate = new Date(userSubscription.current_term_end * 1000);

			if (trialEnd > currentTimestamp) {
				CancelDate = new Date(trialEnd);
			}

			const formattedDate = `${CancelDate.getDate().toString().padStart(2, "0")}/${(CancelDate.getMonth() + 1).toString().padStart(2, "0")}/${CancelDate.getFullYear()}`;
			setCancellationDate(formattedDate);
			setItemName(parseItemName(userSubscription));

			if (userSubscription.cancelled_at > 0) {
				setDisabled(true);
			}
		} else {
			setDisabled(true);
		}
	}, [userSubscription]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const cancelSubscription = async () => {
		try {
			const token = await getAccessTokenSilently();
			const header = { Authorization: `Bearer ${token}` };
			const subscriptionplans = await fetch(apiEndpoint, {
				method: httpMethod,
				headers: header,
				credentials: "include",
			});

			if (!subscriptionplans.ok) {
				throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
			}
		} catch (error) {
			console.log("Something went wrong", error);
		}
		setOpen(false);
		setDisabled(true);
	};

	return (
		<Box>
			<CustomButton variant="contained" onClick={handleClickOpen} disabled={disabled}>
				Cancel {buttonText}
			</CustomButton>
			<ModalWithTitle
				open={open}
				onClose={handleClose}
				aria-labelledby="cancel-subscription-title"
				title={`Cancel ${buttonText}`}
			>
				<Box sx={{ textAlign: "center", marginTop: 2 }}>
					<Typography>
						Do you wish to cancel your{" "}
						<span style={{ fontWeight: "600" }}>{itemName}</span> {buttonText}?
					</Typography>
					<Typography>
						If you choose to do so, it'll take effect on{" "}
						<span style={{ fontWeight: "600" }}>{cancellationDate}</span>.
					</Typography>
					<Typography>
						You will still be able to utilize your {buttonText} until then.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						marginTop: "16px",
					}}
				>
					<CustomButton
						variant="contained"
						onClick={() => cancelSubscription()}
						sx={{ backgroundColor: "error.main" }}
					>
						Cancel {buttonText}
					</CustomButton>
				</Box>
			</ModalWithTitle>
		</Box>
	);
};

export default CancelSubscription;
