import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import Box from "@mui/material/Box";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { getGradientBg } from "../Style/chartStyleUtils";
import { hexToRgba } from "../Style/styleUtils";
import { color } from "../Style/theme";
import { seasonNameShortener } from "../Util/textFormatting";

Chart.register(...registerables, annotationPlugin);

const Powerrank = (props) => {
	const chartRef = useRef(null);

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});
	const [maxYvalueForGraph, setMaxYvalueForGraph] = useState(0);

	const loadData = (data) => {
		const sortedData = data.filter((item) => item.SeasonName);
		const processedData = sortedData.sort((a, b) => {
			const [startYearA, endYearA] = a.SeasonName.split("/").map(Number);
			const [startYearB, endYearB] = b.SeasonName.split("/").map(Number);

			// Compare the start years first
			if (startYearA !== startYearB) {
				return startYearA - startYearB;
			}

			// If start years are the same, compare the end years
			return endYearA - endYearB;
		});

		const labels = processedData.map((item) => item.SeasonName);
		const datasets = [
			{
				label: "Rank",
				data: processedData.map((PR) => PR.PowerRank),
				borderColor: color.secondary.main,
				fill: "start",
				backgroundColor: getGradientBg(hexToRgba(color.secondary.main, 0.5)),
			},
		];

		const yValue = Math.max(...processedData.map((PR) => PR.PowerRank)) * 2;
		setMaxYvalueForGraph(yValue);
		setChartData({ labels, datasets });
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				min: 1,
				max: maxYvalueForGraph,
				reverse: true,
			},
			x: {
				ticks: {
					callback: function (value) {
						const scale = this;
						const season = scale.getLabelForValue(value);
						return seasonNameShortener(season);
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					labelColor: (context) => {
						const dataset = context.dataset;
						return {
							backgroundColor: dataset.borderColor,
						};
					},
				},
			},
		},
	};

	/*
		Important note! Chart.js uses its parent container to update the canvas render and display sizes. 
		This method requires the container to be relatively positioned and 
		dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
		relative values for the container size
	*/
	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				maxHeight: { sx: "80vh", lg: "50vh" },
			}}
		>
			<Line
				data={chartData}
				options={chartOptions}
				width={600}
				height={500}
				ref={chartRef}
			/>
		</Box>
	);
};

export default Powerrank;
