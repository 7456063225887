import { Box, styled } from "@mui/material";
import dayjs from "dayjs";
import ModalWithTitle from "../Components/Modal/ModalWithTitle";
import profile from "../Logos/empty_profile.png";
import { maxOneDecimal } from "../Util/textFormatting";
import AcademyPlayerIcon from "./AcademyPlayerIcon";

const wrapperStyle = {
	display: "flex",
	gap: { xs: 3, sm: 2 },
	flexDirection: { xs: "column", sm: "row" },
};

const imageColumnStyle = {
	display: "flex",
	justifyContent: { xs: "center", sm: "flex-start" },
	alignItems: "center",
	flexDirection: "column",
	gap: 2,
};

const textColumnStyle = {
	display: "flex",
	flexDirection: "column",
	gap: 1,
	flex: 2,
};

const imgStyle = {
	width: "130px",
	height: "auto",
};

const TextRow = styled("span")(() => ({
	"span:nth-of-type(1)": {
		fontWeight: 700,
	},
}));

const PlayerInfoModal = ({ player, onClose }) => {
	return (
		<ModalWithTitle
			open={!!player}
			title={`${player.FirstName} ${player.LastName}`}
			onClose={onClose}
			maxWidth="md"
		>
			<Box sx={wrapperStyle}>
				<Box sx={imageColumnStyle}>
					<img
						src={player.ImageUrl ?? profile}
						style={imgStyle}
						alt={player.ImageUrl ? player.Name : "Empty profile image"}
					/>
					{player.AcademyClubIds?.includes(player.ClubId) && (
						<AcademyPlayerIcon sx={{ marginTop: "3px" }} displayChip={true} />
					)}
				</Box>
				<Box sx={{ ...textColumnStyle, flex: 2 }}>
					<TextRow>
						<span>Position: </span>
						<span>{player.PositionName ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Foot: </span>
						<span>{player.Foot ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Date of Birth: </span>
						<span>
							{player.DateOfBirth ? dayjs(player.DateOfBirth).format("YYYY-MM-DD") : "-"}
						</span>
					</TextRow>
					<TextRow>
						<span>Birthplace: </span>
						<span>{player.BirthPlaceArea ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Passport: </span>
						<span>{player.PassPortArea ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Height: </span>
						<span> {player.Height ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Weight: </span>
						<span>{player.Weight ?? "-"}</span>
					</TextRow>
					<TextRow>
						<span>Contract Until: </span>
						<span>
							{player.ContractExpiration
								? dayjs(player.ContractExpiration).format("YYYY-MM-DD")
								: "-"}{" "}
						</span>
					</TextRow>
					<TextRow>
						<span>Transfer KPI: </span>
						<span>{player.TransferKPI ? maxOneDecimal(player.TransferKPI) : "-"}</span>
					</TextRow>

					<TextRow>
						<span>Academies: </span>
						<span>{player.AcademyClubNames ?? "-"}</span>
					</TextRow>
				</Box>
			</Box>
		</ModalWithTitle>
	);
};

export default PlayerInfoModal;
