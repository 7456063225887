import React, { useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, styled, Switch, Typography } from "@mui/material";

const NoCardsSelected = () => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="30vh"
			textAlign="center"
			padding={2}
		>
			<Typography variant="h6" color="textSecondary">
				Select at least one card to view detailed insights.
			</Typography>
		</Box>
	);
};

export default NoCardsSelected;
