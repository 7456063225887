import React, { useEffect, useMemo, useState } from "react";
import OkCancelModal from "../Components/Modal/OkCancelModal";
import "../Style/am_style.css";
import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	TextField,
	Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ClubSelect from "../Components/Inputs/ClubSelect";
import { auth0Service } from "../Util/Auth0Client";
import { country } from "../Util/country";
import { position } from "../Util/position";

const wrapperStyle = {
	display: "grid",
	gridTemplateColumns: "1fr 1fr",
	gap: (theme) => theme.spacing(0, 2),
	justifyContent: "center",
	width: "100%",
	alignItems: "end",
	"& > :last-child": {
		gridColumn: "1 / -1", // Makes the last item span both columns
	},
};

// mode === "Report" | "Edit"
const ReportOrEditPlayerModal = ({ open, player, onSave, onClose, mode }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [firstname, setFirstname] = useState(player.FirstName);
	const [lastname, setLastname] = useState(player.LastName);
	const [positionId, setPositionId] = useState(player.PositionId);
	const [birthplaceAreaId, setBirthplaceAreaId] = useState(player.BirthPlaceAreaId);
	const [height, setHeight] = useState(player.Height);
	const [weight, setWeight] = useState(player.Weight);
	const [foot, setFoot] = useState(player.Foot);
	const [passportAreaId, setPassportAreaId] = useState(player.PassPortAreaId);
	const [dateOfBirth, setDateOfBirth] = useState(
		player.DateOfBirth ? dayjs(player.DateOfBirth) : dayjs()
	);
	const [contractExpiration, setContractExpiration] = useState(
		player?.ContractExpiration ? dayjs(player?.ContractExpiration) : dayjs()
	);
	const [playerAcademies, setPlayerAcademies] = useState(player.AcademyClubIds ?? []);

	const [countries, setCountries] = useState([]);
	const [positions, setPositions] = useState([]);
	const [academyOptions, setAcademyOptions] = useState([]);
	const [loadingOptions, setLoadingOptions] = useState(false);

	useEffect(() => {
		country.fetchData().then((data) => {
			setCountries(data);
		});
		position.fetchData().then((data) => {
			setPositions(data);
		});
	}, []);

	useEffect(() => {
		const fetchAllClubs = async () => {
			setLoadingOptions(true);
			const token = await auth0Service.getToken();
			const headers = { Authorization: `Bearer ${token}` };
			const response = await fetch(`/api/clubs`, {
				headers,
				credentials: "include",
				method: "GET",
			});

			const clubData = await response.json();
			setAcademyOptions(clubData);
			setLoadingOptions(false);
		};

		fetchAllClubs();
	}, []);

	const positionsList = useMemo(() => {
		return positions.map((position) => ({
			PositionId: position.PositionId,
			PositionName: position.PositionName,
			PositionGroupName: position.PositionGroupName,
		}));
	}, [positions]);

	const countriesList = useMemo(() => {
		return countries.map((country) => ({
			AreaId: country.AreaId,
			AreaName: country.AreaName,
		}));
	}, [countries]);

	const generateSuggestions = (min, max) => {
		let heights = [];
		for (let i = min; i <= max; i++) {
			heights.push(i);
		}
		return heights;
	};

	const heightSuggestions = generateSuggestions(155, 250);
	const weightSuggestions = generateSuggestions(50, 150);
	const footOptions = ["left", "right", "both"];

	const handleSubmit = async () => {
		setIsLoading(true);
		await onSave(
			positionId,
			birthplaceAreaId,
			dateOfBirth,
			contractExpiration,
			foot,
			height,
			weight,
			passportAreaId,
			lastname,
			firstname,
			playerAcademies
		);
		setIsLoading(false);
	};

	const handleLastNameChange = (event) => {
		setLastname(event.target.value);
	};
	const handleFirstNameChange = (event) => {
		setFirstname(event.target.value);
	};
	const handlePositionChange = (event) => {
		setPositionId(event.target.value);
	};

	const handleBirthPlaceChange = (event) => {
		setBirthplaceAreaId(event.target.value);
	};

	const handleWeightChange = (event, value) => {
		if (value !== null) {
			setWeight(value);
		}
	};

	const handleContractChange = (date) => {
		if (date && date.isValid()) {
			setContractExpiration(date);
		} else {
			console.error("Invalid date:", date);
		}
	};

	const handlePassportAreaChange = (event) => {
		setPassportAreaId(event.target.value);
	};

	const handleFootChange = (event, value) => {
		if (value != null) {
			setFoot(value);
		}
	};

	const handleHeightChange = (event, value) => {
		if (value !== null) {
			setHeight(value);
		}
	};

	const handleDateChange = (date) => {
		if (date && date.isValid()) {
			setDateOfBirth(date);
		} else {
			console.error("Invalid date:", date);
		}
	};

	return (
		<OkCancelModal
			open={open}
			title={`${mode} ${player.FirstName} ${player.LastName}`}
			onOk={handleSubmit}
			onClose={onClose}
			okText={mode === "Report" ? "Report" : "Update"}
		>
			<Typography sx={{ mt: 0 }}>
				Please provide the corrected data in the fields below.
			</Typography>

			<Box sx={wrapperStyle}>
				<FormControl>
					<TextField
						id="firstname-select"
						value={firstname}
						onChange={(e) => handleFirstNameChange(e)}
						label={"Firstname"}
					/>
				</FormControl>
				<FormControl>
					<TextField
						id="lastname-select"
						value={lastname}
						onChange={(e) => handleLastNameChange(e)}
						label={"Lastname"}
					/>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="position-select"
						options={positionsList}
						getOptionLabel={(option) => option.PositionName}
						value={
							positionsList.find((position) => position.PositionId === positionId) || null
						}
						onChange={(event, newValue) => {
							handlePositionChange({
								target: { value: newValue ? newValue.PositionId : "" },
							});
						}}
						renderInput={(params) => <TextField {...params} label={`Position`} />}
						groupBy={(option) => option.PositionGroupName}
					/>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="birthplace-select"
						options={countriesList}
						getOptionLabel={(option) => option.AreaName}
						value={
							countriesList.find((country) => country.AreaId === birthplaceAreaId) || null
						}
						onChange={(event, newValue) => {
							handleBirthPlaceChange({
								target: { value: newValue ? newValue.AreaId : "" },
							});
						}}
						renderInput={(params) => <TextField {...params} label={`Birthplace`} />}
					/>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="nation-select"
						options={countriesList}
						getOptionLabel={(option) => option.AreaName}
						value={
							countriesList.find((country) => country.AreaId === passportAreaId) || null
						}
						onChange={(event, newValue) => {
							handlePassportAreaChange({
								target: { value: newValue ? newValue.AreaId : "" },
							});
						}}
						renderInput={(params) => <TextField {...params} label={`Nationality`} />}
					/>
				</FormControl>
				<FormControl>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Date of birth"
							value={dateOfBirth}
							onChange={handleDateChange}
							renderInput={(params) => <TextField {...params} />}
							maxDate={dayjs()}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Contract expiration"
							value={contractExpiration}
							onChange={handleContractChange}
							renderInput={(params) => <TextField {...params} />}
							minDate={dayjs()}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="foot-select"
						options={footOptions}
						getOptionLabel={(option) => `${option}`}
						renderInput={(params) => (
							<TextField {...params} label={`Foot`} variant="outlined" />
						)}
						value={foot}
						onChange={handleFootChange}
						disableClearable
						freeSolo
					/>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="height-select"
						options={heightSuggestions}
						getOptionLabel={(option) => `${option}`}
						renderInput={(params) => (
							<TextField {...params} label={`Height (cm)`} variant="outlined" />
						)}
						value={height}
						onChange={handleHeightChange}
						disableClearable
						freeSolo
					/>
				</FormControl>
				<FormControl>
					<Autocomplete
						id="weight-select"
						options={weightSuggestions}
						getOptionLabel={(option) => `${option}`}
						renderInput={(params) => (
							<TextField {...params} label={`Weight (kg)`} variant="outlined" />
						)}
						value={weight}
						onChange={handleWeightChange}
						disableClearable
						freeSolo
					/>
				</FormControl>
				<FormControl>
					<ClubSelect
						label="Academies"
						options={academyOptions}
						loadingOptions={loadingOptions}
						multiple={true}
						value={playerAcademies}
						onValueChange={(newValues) =>
							setPlayerAcademies(newValues.map((v) => v.ClubId))
						}
					/>
				</FormControl>
			</Box>

			{isLoading && (
				<Box sx={{ textAlign: "center" }}>
					<CircularProgress />
				</Box>
			)}
		</OkCancelModal>
	);
};

export default ReportOrEditPlayerModal;
