// TokenVerifier.jsx
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const TokenVerifier = () => {
	const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

	useEffect(() => {
		const checkTokenIssuedAt = async () => {
			if (isAuthenticated) {
				try {
					const token = await getAccessTokenSilently();
					if (token) {
						const decodedToken = JSON.parse(atob(token.split(".")[1]));
						const issuedAt = decodedToken.iat;
						const lastDeployment = Number(process.env.REACT_APP_LAST_DEPLOYMENT);

						if (isNaN(lastDeployment)) {
							console.error("REACT_APP_LAST_DEPLOYMENT is not a valid number.");
							console.error(
								"REACT_APP_LAST_DEPLOYMENT:",
								process.env.REACT_APP_LAST_DEPLOYMENT
							);
							console.error("lastDeployment:", lastDeployment);

							return;
						}

						if (issuedAt < lastDeployment) {
							localStorage.removeItem("ClubSummaryStore");
							localStorage.removeItem("ImpersonationStore");
							localStorage.removeItem("ProfileStore");
							localStorage.removeItem("UserEntitlementStore");

							logout({ returnTo: window.location.origin });
						}
					}
				} catch (error) {
					console.error("Error fetching token silently:", error);
				}
			}
		};

		checkTokenIssuedAt();
	}, [isAuthenticated, getAccessTokenSilently, logout]);

	return null;
};

export default TokenVerifier;
