import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

const titleStyle = {
	fontWeight: 700,
	textTransform: "uppercase",
	color: "primary.main",
	paddingRight: "50px", // leave room for close btn
};

const closeBtnStyle = {
	position: "absolute",
	top: 8,
	right: 8,
};

const ModalWithTitle = ({
	open,
	children,
	title,
	onClose,
	maxWidth,
	disableContentPadding = false,
}) => {
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
			<DialogTitle sx={titleStyle}>{title}</DialogTitle>
			<IconButton aria-label="close" onClick={handleClose} sx={closeBtnStyle}>
				<CloseIcon />
			</IconButton>
			{disableContentPadding ? children : <DialogContent>{children}</DialogContent>}
		</Dialog>
	);
};

export default ModalWithTitle;
