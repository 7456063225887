import React from "react";
import { Box, styled, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MarketValueAssessmentComparison from "./MarketValueAssessmentComparison/Marketvalueassessmentcomparison";
import MarketValueComparison from "./Marketvaluecomparison";
import PlayerMarketValueAssessment from "./Playermarketvalueassessment";
import PlayTimeDistribution from "./Playtimedistribution";
import Powerrank from "./Powerrank";
import TransferKPI from "./Transferkpi";
import TransferKPIComparison from "./Transferkpicomparison";
import TransferKPIRisk from "./Transferkpirisk";
import "./Dashboard.css";
import { descriptions } from "../Util/chartDescriptions";
import chartTitles from "../Util/chartTitles";
import ChartSection from "./components/ChartSection";

const chartsContainerStyle = {
	display: "flex",
	flexDirection: "column",
};

const CustomSection = styled(Box, {
	shouldForwardProp: (prop) => prop !== "order",
})(({ order }) => ({
	order: order,
}));

const DashboardChartContainer = ({ selectedCards, data, chartRefs }) => {
	return (
		<Box sx={chartsContainerStyle}>
			{selectedCards.includes("transferKPI") && (
				<CustomSection order={selectedCards.indexOf("transferKPI")}>
					<Typography variant="h2">{chartTitles.transferKPI}</Typography>
					{data?.KPI?.length > 0 ? (
						<>
							<div
								ref={(el) =>
									(chartRefs.current[selectedCards.indexOf("transferKPI")] = el)
								}
							>
								<ChartSection
									title="Per player"
									updateFrequency="after each league game"
									description={descriptions.transferKpi}
									notes={descriptions.transferKpiNotes}
								>
									<TransferKPI data={data.KPI ?? []} />
								</ChartSection>
							</div>
							<div
								ref={(el) =>
									(chartRefs.current[selectedCards.indexOf("transferKPI +1")] = el)
								}
							>
								<ChartSection
									title="Club comparison"
									description={descriptions.transferKpiComparison}
									updateFrequency="weekly"
								>
									<TransferKPIComparison data={data.TK ?? []} />
								</ChartSection>
							</div>
						</>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}

			{selectedCards.includes("squadValue") && (
				<CustomSection order={selectedCards.indexOf("squadValue")}>
					<Typography variant="h2">{chartTitles.squadValue}</Typography>
					{data?.MCV?.length > 0 ? (
						<div
							ref={(el) => (chartRefs.current[selectedCards.indexOf("squadValue")] = el)}
						>
							<ChartSection
								description={descriptions.marketValueComparison}
								updateFrequency="weekly"
								notes={descriptions.marketValueComparisonNotes}
							>
								<MarketValueComparison data={data.MCV ?? []} />
							</ChartSection>
						</div>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}

			{selectedCards.includes("powerRank") && (
				<CustomSection order={selectedCards.indexOf("powerRank")}>
					<Typography variant="h2">{chartTitles.powerRank}</Typography>
					{data?.PR?.length > 0 ? (
						<div
							ref={(el) => (chartRefs.current[selectedCards.indexOf("powerRank")] = el)}
						>
							<ChartSection description={descriptions.powerRank} updateFrequency="weekly">
								<Powerrank data={data.PR ?? []} />
							</ChartSection>
						</div>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}

			{selectedCards.includes("playTimeDistribution") && (
				<CustomSection order={selectedCards.indexOf("playTimeDistribution")}>
					<Typography variant="h2">{chartTitles.playTimeDistribution}</Typography>
					{data?.PTD?.length > 0 ? (
						<div
							ref={(el) =>
								(chartRefs.current[selectedCards.indexOf("playTimeDistribution")] = el)
							}
						>
							<ChartSection
								description={descriptions.playTimeDistribution}
								updateFrequency="weekly"
							>
								<PlayTimeDistribution data={data.PTD ?? []} />
							</ChartSection>
						</div>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}

			{selectedCards.includes("transferKPIRiskLevel") && (
				<CustomSection order={selectedCards.indexOf("transferKPIRiskLevel")}>
					<Typography variant="h2">{chartTitles.transferKPIRiskLevel}</Typography>
					{data?.TKR?.length > 0 ? (
						<div
							ref={(el) =>
								(chartRefs.current[selectedCards.indexOf("transferKPIRiskLevel")] = el)
							}
						>
							<ChartSection
								title="Squad Distribution"
								description={descriptions.transferKpiRisk}
								updateFrequency="weekly"
								descriptionAbove={true}
							>
								<TransferKPIRisk data={data.TKR ?? []} comparisonData={data.TK ?? []} />
							</ChartSection>
						</div>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}

			{selectedCards.includes("GVSO") && (
				<CustomSection order={selectedCards.indexOf("GVSO")}>
					<Typography variant="h2">{chartTitles.GVSO}</Typography>
					{data?.PMA?.length > 0 ? (
						<>
							<div ref={(el) => (chartRefs.current[selectedCards.indexOf("GVSO")] = el)}>
								<ChartSection
									description={descriptions.playerMarketValueAssessment}
									updateFrequency="weekly"
								>
									<PlayerMarketValueAssessment data={data.PMA ?? []} />
								</ChartSection>
							</div>
							<MarketValueAssessmentComparison
								data={data?.MCAC ?? []}
								addChartRef={(el, index) => {
									chartRefs.current[selectedCards.indexOf("GVSO +1") + index] = el;
								}}
							/>
						</>
					) : (
						<CircularProgress />
					)}
				</CustomSection>
			)}
		</Box>
	);
};

export default DashboardChartContainer;
