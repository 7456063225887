import React, { useEffect, useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import Box from "@mui/material/Box";
import { Chart, registerables } from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import "../Style/am_style.css";
import "./Datapage.css";
import { getGradientBg } from "../Style/chartStyleUtils";
import { hexToRgba } from "../Style/styleUtils";
import { color } from "../Style/theme";
import { seasonNameShortener } from "../Util/textFormatting";

Chart.register(...registerables);

const TransferKPIComparison = (props) => {
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});
	const chartRef = useRef(null);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				min: 0,
				suggestedMax: 40,
				beginAtZero: true,
			},
			x: {
				ticks: {
					callback: function (value) {
						const scale = this;
						const season = scale.getLabelForValue(value);
						return seasonNameShortener(season);
					},
				},
			},
		},
		plugins: {
			legend: {
				labels: {
					generateLabels: (chart) => {
						// Call the original function to get default labels
						const labels = Chart.defaults.plugins.legend.labels.generateLabels.call(
							this,
							chart
						);

						return labels.map((label, index) => ({
							...label,
							lineWidth: 0, // Remove border by setting lineWidth to 0
							fillStyle: chart.data.datasets[index].borderColor, // Use dataset's borderColor as fill color
						}));
					},
				},
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || "";
						if (label) {
							label += ": ";
						}
						if (context.parsed.y !== null) {
							label += context.parsed.y?.toFixed(2);
						} else {
							label += "N/A";
						}
						return label;
					},
					labelColor: (context) => {
						const dataset = context.dataset;
						return {
							backgroundColor: dataset.borderColor,
						};
					},
				},
			},
		},
	};

	const [uniqueSeasons, setUniqueSeasons] = useState([]);

	useEffect(() => {
		const loadData = (data) => {
			var sortedData = data.filter((item) => item.SeasonName);
			sortedData.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));
			const processedData = sortedData.map((KPI) => ({
				...KPI,
				TeamTransferKPI: KPI.TeamTransferKPI === 0 ? null : KPI.TeamTransferKPI,
				LeagueAvgTransferKPI:
					KPI.LeagueAvgTransferKPI === 0 ? null : KPI.LeagueAvgTransferKPI,
			}));

			let uniqueSeasons = [...new Set(sortedData.map((item) => item.SeasonName))];
			setUniqueSeasons(uniqueSeasons);

			const labels = processedData.map((KPI) => KPI.SeasonName);
			const datasets = [
				{
					label: "League average Transfer KPI",
					data: processedData.map((KPI) =>
						KPI.LeagueAvgTransferKPI !== null
							? Number(KPI.LeagueAvgTransferKPI?.toFixed(2))
							: null
					),
					borderColor: color.primary.main,
					backgroundColor: color.primary.main,
				},
				{
					label: "Club Transfer KPI",
					data: processedData.map((KPI) =>
						KPI.TeamTransferKPI !== null ? Number(KPI.TeamTransferKPI?.toFixed(2)) : null
					),
					borderColor: color.secondary.main,
					fill: true,
					backgroundColor: getGradientBg(hexToRgba(color.secondary.main, 0.5)),
				},
			];
			setChartData({ labels, datasets });
		};

		loadData(props.data);
	}, [props.data]);

	/*
		Imoportant note! Chart.js uses its parent container to update the canvas render and display sizes. 
		This method requires the container to be relatively positined and 
		dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
		relative values for the container size
	*/
	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				maxHeight: { sx: "80vh", lg: "50vh" },
			}}
		>
			{uniqueSeasons.length > 1 ? (
				<Line
					data={chartData}
					options={chartOptions}
					width={500}
					height={450}
					ref={chartRef}
				/>
			) : (
				<Bar
					data={chartData}
					options={chartOptions}
					width={500}
					height={450}
					ref={chartRef}
					plugins={[datalabels]}
				/>
			)}
		</Box>
	);
};

export default TransferKPIComparison;
