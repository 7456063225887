import React from "react";
import { Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { profileStore } from "../../State/ProfileStore";
import "./BlurredOverlay.css";
import { routes } from "../../Util/routes";

const CustomButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	backgroundColor: theme.palette.info.main,
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const BlurredOverlay = ({ entitlementType }) => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		profileStore.setCurrentTabIndex(0);
		navigate(routes.am.profile);
	};

	const isSubscription = entitlementType === "subscription";

	return (
		<div className="component-wrapper">
			<div className="blurred-overlay">
				<Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
					Access Denied
				</Typography>
				<Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
					{isSubscription
						? "You currently don't have an active subscription to view this page."
						: "You currently don't have access to this add-on feature. Please upgrade your subscription."}
					<br />
					Please click the button below to {isSubscription ? "subscribe" : "upgrade"}.
				</Typography>
				<CustomButton variant="contained" onClick={handleButtonClick}>
					{isSubscription ? "choose Subscription" : "Add feature"}
				</CustomButton>
			</div>
		</div>
	);
};

export default BlurredOverlay;
