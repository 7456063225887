import React from "react";
import { Bar } from "react-chartjs-2";
import { color } from "../Style/theme";
import { maxOneDecimal } from "../Util/textFormatting";

const BarChart = ({ data, successfulData = {}, labels }) => {
	const successfulDataLeft = successfulData.left || [];
	const successfulDataRight = successfulData.right || [];

	const processedFlankData = {
		left: data.left.map((total, index) => total - (successfulDataLeft[index] || 0)),
		right: data.right.map((total, index) => total - (successfulDataRight[index] || 0)),
	};

	const chartData = {
		labels: labels,
		datasets: [
			{
				label: "Successful Crosses - Left",
				data: successfulDataLeft,
				backgroundColor: color.primary.light,
				borderColor: color.primary.light,
				categoryPercentage: 0.8,
				barPercentage: 0.6,
				order: 0,
				grouped: true,
				barThickness: 50,
				stack: "Stack 1",
			},
			{
				label: "Successful Crosses - Right",
				data: successfulDataRight,
				backgroundColor: color.secondary.light,
				borderColor: color.secondary.light,
				categoryPercentage: 0.8,
				barPercentage: 0.6,
				order: 0,
				grouped: true,
				barThickness: 50,
				stack: "Stack 2",
			},
			{
				label: "Left",
				data: processedFlankData.left,
				backgroundColor: color.primary.main,
				borderColor: color.primary.main,
				categoryPercentage: 0.8,
				barPercentage: 0.8,
				barThickness: 50,
				order: 1,
				borderWidth: 0,
				stack: "Stack 1",
			},
			{
				label: "Right",
				data: processedFlankData.right,
				backgroundColor: color.secondary.main,
				borderColor: color.secondary.main,
				categoryPercentage: 0.8,
				barPercentage: 0.8,
				barThickness: 50,
				order: 1,
				borderWidth: 0,
				stack: "Stack 2",
			},
		],
	};

	const options = {
		borderRadius: 4,
		scale: {
			x: {
				stacked: true,
			},
			y: {
				beginAtZero: true,
			},
			ticks: {
				stepSize: 1,
				suggestedMin: 0,
				suggestedMax: 10,
			},
		},
		interaction: {
			mode: "x",
		},
		plugins: {
			legend: {
				labels: {
					filter: function (item) {
						// Filter out "Successful Crosses" items from the legend
						return !item.text.includes("Successful Crosses");
					},
				},
			},
			tooltip: {
				callbacks: {
					title: function (context) {
						const label = context[0].label;
						const datasetLabel = context[0].dataset.label;
						const index = context[0].dataIndex;

						switch (label) {
							case "Crosses":
								const side = datasetLabel.includes("Left") ? "left" : "right";
								const totalCrosses =
									(processedFlankData[side][index] || 0) +
									(successfulData[side][index] || 0);
								return `Crosses: ${totalCrosses}`;
							case "Flank Attacks":
								return "Flank attacks";
							case "Avg Attacks":
								return "Average flank attacks in league";
							case "Avg Crosses":
								return "Average crosses in league";
							case "Average in League":
								return "Average xG in league";
							default:
								return label;
						}
					},
					label: function (context) {
						const datasetLabel = context.dataset.label;
						let value = context.raw;
						const label = context.label;

						if (value === null || value === undefined) {
							value = 0;
						}

						if (label === "Crosses") {
							const crossType = datasetLabel.includes("Successful Crosses")
								? "Successful Crosses"
								: `Unsuccessful Crosses - ${datasetLabel}`;
							return `${crossType}: ${maxOneDecimal(value)}`;
						} else if (datasetLabel.includes("Successful Crosses")) {
							return "";
						} else if (label === "Flank Attacks") {
							return `${datasetLabel}: ${maxOneDecimal(value)}`;
						}
						return `${datasetLabel}: ${value.toFixed(2)}`;
					},
				},
			},
			watermark: {
				xAlign: "right",
				yAlign: "top",
			},
		},
	};

	return <Bar data={chartData} options={options} width={600} height={500} />;
};

export default BarChart;
