import { Fragment, useState } from "react";
import { Info, InfoOutlined } from "@mui/icons-material";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	styled,
	Typography,
} from "@mui/material";
import UpdateInfo from "./UpdateInfo";

const cardStyle = {
	width: "100%",
	backgroundColor: "background.light",
	height: "max-content",
	px: {
		xs: 2,
		lg: 3,
		xxl: 4,
	},
	pt: {
		xs: 2,
		lg: 3,
	},
	mb: {
		xs: 2,
		lg: 4,
	},
};

const headerStyle = {
	textTransform: "uppercase",
	p: 0,
	pb: {
		xs: 2,
		lg: 4,
	},
};

// Box that wraps the chart and description
const ChartAndDescBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== "expanded" && prop !== "descriptionAbove",
})(({ theme, expanded, descriptionAbove }) => {
	const styles = {
		display: "grid",
		gap: theme.spacing(2),
		gridTemplateColumns: "100%",
		gridTemplateRows: expanded === "true" ? "max-content 1fr" : "1fr",
	};

	return descriptionAbove === "true"
		? styles
		: {
				...styles,

				[theme.breakpoints.up("xl")]: {
					gap: theme.spacing(4),
					gridTemplateColumns: expanded === "true" ? "60% 1fr" : "100% 1fr", // chart column width must be set in percentage
					gridTemplateRows: "1fr",
					">:first-of-type": {
						order: 2,
					},
				},
			};
});

const collapseStyle = { maxWidth: "100%" };

const contentStyle = {
	p: 0,
	pb: 2,
};

const footerStyle = {
	px: 0,
};

const notesTitle = {
	fontWeight: "bold",
	color: "primary.main",
};

const notesListStyle = {
	listStyle: "none",
	padding: 0,
};

const ChartSection = ({
	children,
	title,
	description,
	notes, // pass in jsx <li> elements
	updateFrequency,
	descriptionAbove,
}) => {
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Card sx={cardStyle} elevation={3}>
			<CardHeader
				sx={headerStyle}
				title={title ?? undefined}
				action={
					<IconButton onClick={handleExpandClick} aria-label="Description">
						{expanded ? (
							<Info sx={{ color: "mono.first" }} />
						) : (
							<InfoOutlined sx={{ color: "mono.first" }} />
						)}
					</IconButton>
				}
			/>
			<ChartAndDescBox
				expanded={expanded.toString()}
				descriptionAbove={descriptionAbove?.toString()}
			>
				<Collapse
					in={expanded}
					timeout="auto"
					sx={collapseStyle}
					orientation={"vertical"}
				>
					<CardContent sx={contentStyle}>
						<Typography variant="h4">Description </Typography>

						<Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
							{description}
						</Typography>
						{!!notes?.length && (
							<>
								<Typography sx={notesTitle}>Note: </Typography>
								<ul style={notesListStyle}>
									{notes.map((n, i) => (
										<Fragment key={i}>{n}</Fragment>
									))}
								</ul>
							</>
						)}
					</CardContent>
				</Collapse>
				<CardContent sx={contentStyle}>{children}</CardContent>
			</ChartAndDescBox>
			<CardContent sx={footerStyle}>
				<UpdateInfo updateFrequency={updateFrequency} />
			</CardContent>
		</Card>
	);
};

export default ChartSection;
