import React, { useState } from "react";
import "../Datapage/Datapage.css";
import CustomTable from "../Components/Table";
import { maxOneDecimal } from "../Util/textFormatting";
import RadarChart from "./RadarChart";

const ChanceChart = ({ firstClubData, secondClubData, leagueAvg }) => {
	const data1 = {
		team: [
			firstClubData.P_xG,
			firstClubData.P_xGPerShot,
			firstClubData.P_PositionalWithShots,
			firstClubData.P_TouchesInBox,
		],
		opponents: [
			secondClubData.P_xG,
			secondClubData.P_xGPerShot,
			secondClubData.P_PositionalWithShots,
			secondClubData.P_TouchesInBox,
		],
		leagueAvg: [0, 0, 0, 0],
	};

	const labels1 = [
		"xG",
		"xG per shot",
		"Positional attacks with shots",
		"Touches in penalty area",
	];

	const tableData = [
		{
			team: firstClubData?.ClubGroup,
			avgShotLength: parseFloat(firstClubData?.Avg_ShotDistance)?.toFixed(1),
		},
		{
			team: secondClubData?.ClubGroup,
			avgShotLength: parseFloat(secondClubData?.Avg_ShotDistance)?.toFixed(1),
		},
		{
			team: "League average",
			avgShotLength: parseFloat(leagueAvg?.AvgShotDistance)?.toFixed(1),
		},
	];

	const [tableColumns] = useState([
		{ field: "team", headerName: "Team" },
		{
			field: "avgShotLength",
			headerName: "Avg shot length (m)",
			customStyle: { textAlign: "center" },
		},
	]);

	return (
		<div
			className="chart-section"
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				height: "100%",
			}}
		>
			<div>
				<h2>High values indicate positive performance</h2>
				<RadarChart
					teamName={firstClubData.ClubGroup}
					secondClubName={secondClubData.ClubGroup}
					data={data1}
					labels={labels1}
				/>
			</div>

			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<h2>Low values indicate positive performance</h2>

				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<CustomTable tableData={tableData} tableColumns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default ChanceChart;
