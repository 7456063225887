import React from "react";
import { Chart, registerables } from "chart.js";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthProviderWithHistory from "./Authorization/AuthProviderWithHistory";
import crosshairPlugin from "./Components/CrossHairPlugin";
import customWatermarkPlugin from "./Components/Watermark";
import reportWebVitals from "./reportWebVitals";
import { hexToRgba } from "./Style/styleUtils";
import { color } from "./Style/theme";

Chart.register(...registerables, crosshairPlugin, customWatermarkPlugin);

Chart.defaults.font.family = "Montserrat, sans-serif";
Chart.defaults.font.size = 16;
Chart.defaults.font.weight = 400;
Chart.defaults.set("plugins.datalabels", {
	font: { weight: 400 },
});

// Global legend configuration
const originalGenerateLabels = Chart.defaults.plugins.legend.labels.generateLabels;
Chart.defaults.set("plugins.legend", {
	labels: {
		usePointStyle: false,
		boxHeight: 4,
		boxWidth: 22,
		generateLabels: function (chart) {
			// Call the original function
			const labels = originalGenerateLabels.call(this, chart);

			// Customize to remove borders
			return labels.map((label) => ({
				...label,
				lineWidth: 0, // Remove border by setting lineWidth to 0
			}));
		},
	},
	onClick: null,
});

// Global tooltip configuration
Chart.defaults.set("plugins.tooltip", {
	padding: { left: 10, right: 10, top: 10, bottom: 15 },
	boxPadding: 5,
	bodySpacing: 3,
	titleFont: { weight: "bold" },
	usePointStyle: true,
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	borderColor: "#dedede",
	borderWidth: 1,
	titleColor: color.primary.main,
	bodyColor: "#000",
	footerColor: "#000",
	caretPadding: 15,
});

// Global line chart configuration
Chart.defaults.set("datasets.line", {
	borderWidth: 1,
	pointStyle: "circle",
	pointRadius: 0, // hide point when not hovering
	// Hover styling
	pointHoverRadius: 4,
	pointHoverBorderWidth: 8,
	pointHoverBorderColor: (ctx) => {
		const dataset = ctx.dataset;
		const borderColor = dataset.borderColor;
		return hexToRgba(borderColor, 0.5);
	},
	pointHoverBackgroundColor: (ctx) => {
		const dataset = ctx.dataset;
		return dataset.borderColor;
	},
	tension: 0.4, // This enables Bezier curve interpolation
	clip: false,
});

// Global grid configuration
Chart.defaults.scales.linear.grid = {
	color: "#eee",
};
Chart.defaults.scales.category.grid = {
	color: "#eee",
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<BrowserRouter>
		<AuthProviderWithHistory>
			<App />
		</AuthProviderWithHistory>
	</BrowserRouter>
);

reportWebVitals();
