import UpdateIcon from "@mui/icons-material/Update";
import Box from "@mui/material/Box";

const wrapperStyle = {
	display: "flex",
	alignItems: "center",
	gap: 1,
	fontStyle: "italic",
	color: "#666",
};

const UpdateInfo = ({ updateFrequency }) => {
	return (
		<Box sx={wrapperStyle}>
			<UpdateIcon sx={{ fontSize: "20px" }} />{" "}
			<span> Chart updates {updateFrequency}</span>
		</Box>
	);
};

export default UpdateInfo;
