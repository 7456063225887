import React, { useRef } from "react";
import { Radar } from "react-chartjs-2";
import { color } from "../Style/theme";

const RadarChart = ({ data, labels, teamName, secondClubName }) => {
	const chartRef = useRef(null);

	const chartData = {
		labels: labels,
		datasets: [
			{
				label: teamName,
				data: data.team,
				backgroundColor: color.warning.main,
				borderColor: color.warning.main,
				fill: false,
				borderWidth: 2,
			},
			{
				label: secondClubName,
				data: data.opponents,
				backgroundColor: color.secondary.main,
				borderColor: color.secondary.main,
				fill: false,
				borderWidth: 2,
			},
			{
				label: "Avg in league",
				data: data.leagueAvg,
				backgroundColor: color.primary.main,
				borderColor: color.primary.main,
				fill: false,
				borderWidth: 2,
			},
		],
	};

	const options = {
		scales: {
			r: {
				suggestedMin: -3,
				suggestedMax: 3,
				ticks: {
					stepSize: 0.5,
					callback: (value) => (value % 1 === 0 ? value : ""), // Display label only for whole numbers
					backdropPadding: 0,
					backdropColor: "#f8f8f8",
				},
				pointLabels: {
					font: {
						size: 13,
					},
					padding: 0,
				},
			},
		},
	};

	return (
		<Radar data={chartData} options={options} width={600} height={500} ref={chartRef} />
	);
};

export default RadarChart;
