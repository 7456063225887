import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import "chart.js/auto";
import { descriptions } from "../../Util/chartDescriptions";
import "../../Style/style.css";
import { Typography } from "@mui/material";
import { toJS } from "mobx";
import { color } from "../../Style/theme";
import ChartSection from "../components/ChartSection";
import CategorySection from "./CategorySection";

Chart.register(...registerables);

function calculateRanks(data) {
	const groupedData = data.reduce((acc, item) => {
		if (!acc[item.SeasonName]) {
			acc[item.SeasonName] = [];
		}
		acc[item.SeasonName].push(item);
		return acc;
	}, {});

	const rankGroup = (group, key, rankKey) => {
		group.sort((a, b) => b[key] - a[key]);
		let rank = 1;
		for (let i = 0; i < group.length; i++) {
			if (i > 0 && group[i][key] !== group[i - 1][key]) {
				rank = i + 1;
			}
			group[i][rankKey] = rank;
		}
	};

	for (let season in groupedData) {
		if (groupedData.hasOwnProperty(season)) {
			let group = groupedData[season];
			rankGroup(group, "SumGrowth", "GrowthRank");
			rankGroup(group, "SumValue", "ValueRank");
			rankGroup(group, "SumSeniority", "SeniorityRank");
			rankGroup(group, "SumOppCost", "OppCostRank");
		}
	}

	return Object.values(groupedData).flat();
}

const MarketValueAssessmentComparison = ({ data, addChartRef = () => {} }) => {
	const [growthChartData, setGrowthChartData] = useState({ datasets: [] });
	const [valueChartData, setValueChartData] = useState({ datasets: [] });
	const [seniorityChartData, setSeniorityChartData] = useState({
		datasets: [],
	});
	const [oppCostChartData, setOppCostChartData] = useState({ datasets: [] });

	const [growthTableData, setGrowthTableData] = useState([]);
	const [valueTableData, setValueTableData] = useState([]);
	const [seniorityTableData, setSeniorityTableData] = useState([]);
	const [oppCostTableData, setOppCostTableData] = useState([]);

	const [GrowthRanking, setGrowthRanking] = useState([]);
	const [ValueRanking, setValueRanking] = useState([]);
	const [SeniorityRanking, setSeniorityRanking] = useState([]);
	const [OppCostRanking, setOppCostRanking] = useState([]);

	const [maxMarketValue, setMaxMarketValue] = useState(0);

	useEffect(() => {
		const chartBackgroundColor = color.primary.main;
		const clubBackgroundColor = color.secondary.main;

		const loadData = (propdata) => {
			let data = toJS(propdata);
			data = calculateRanks(data);

			const filteredData = data.filter((item) => item.DoShowClubName);

			if (filteredData.length < 1) {
				return;
			}

			data.sort((a, b) => a.GrowthRank - b.GrowthRank);
			const growthRank = data.slice(0, 5).map((item, index) => ({
				id: `growth-${index}`,
				clubName: item.ClubName,
				Rank: item.GrowthRank,
			}));
			setGrowthTableData(growthRank);
			setGrowthChartData({
				labels: [],
				datasets: [
					{
						label: "Growth",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumGrowth,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Growth",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumGrowth,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const GrowthRanking = filteredData[0].GrowthRank;
			setGrowthRanking(GrowthRanking);

			data.sort((a, b) => a.ValueRank - b.ValueRank);
			const valueRank = data.slice(0, 5).map((item, index) => ({
				id: `value-${index}`,
				clubName: item.ClubName,
				Rank: item.ValueRank,
			}));
			setValueTableData(valueRank);
			setValueChartData({
				labels: [],
				datasets: [
					{
						label: "Value",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumValue,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Value",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumValue,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const ValueRanking = filteredData[0].ValueRank;
			setValueRanking(ValueRanking);

			data.sort((a, b) => a.SeniorityRank - b.SeniorityRank);
			const seniorityRank = data.slice(0, 5).map((item, index) => ({
				id: `seniority-${index}`,
				clubName: item.ClubName,
				Rank: item.SeniorityRank,
			}));
			setSeniorityTableData(seniorityRank);
			setSeniorityChartData({
				labels: [],
				datasets: [
					{
						label: "Seniority",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumSeniority,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Seniority",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumSeniority,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const SeniorityRanking = filteredData[0].SeniorityRank;
			setSeniorityRanking(SeniorityRanking);

			data.sort((a, b) => a.OppCostRank - b.OppCostRank);
			const oppCostRank = data.slice(0, 5).map((item, index) => ({
				id: `oppCost-${index}`,
				clubName: item.ClubName,
				Rank: item.OppCostRank,
			}));
			setOppCostTableData(oppCostRank);
			setOppCostChartData({
				labels: [],
				datasets: [
					{
						label: "Opportunity Cost",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumOppCost,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Opportunity Cost",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumOppCost,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const OppCostRanking = filteredData[0].OppCostRank;
			setOppCostRanking(OppCostRanking);

			var maxMarketValue = Math.max(
				...data.map((item) => item.SumValue),
				...data.map((item) => item.SumGrowth),
				...data.map((item) => item.SumSeniority),
				...data.map((item) => item.SumOppCost)
			);
			setMaxMarketValue(maxMarketValue + (1000000 - (maxMarketValue % 1000000)));
		};
		loadData(data);
	}, [data]);

	return (
		<>
			<div>
				<Typography variant="h3">Per category</Typography>
				<Typography variant="body1">
					{descriptions.marketValueAssessmentComparisonSubTitle}
				</Typography>
			</div>

			<div ref={(el) => addChartRef(el, 0)}>
				<ChartSection
					title={"Growth"}
					description={descriptions.marketValueAssessmentComparisonGrowth}
					updateFrequency="weekly"
					descriptionAbove={true}
				>
					<CategorySection
						name="Growth"
						chartData={growthChartData}
						tableData={growthTableData}
						ranking={GrowthRanking}
						maxMarketValue={maxMarketValue}
					/>
				</ChartSection>
			</div>

			<div ref={(el) => addChartRef(el, 1)}>
				<ChartSection
					title={"Value"}
					description={descriptions.marketValueAssessmentComparisonValue}
					updateFrequency="weekly"
					descriptionAbove={true}
				>
					<CategorySection
						name="Value"
						chartData={valueChartData}
						tableData={valueTableData}
						ranking={ValueRanking}
						maxMarketValue={maxMarketValue}
					/>
				</ChartSection>
			</div>

			<div ref={(el) => addChartRef(el, 2)}>
				<ChartSection
					title={"Seniority"}
					description={descriptions.marketValueAssessmentComparisonSeniority}
					updateFrequency="weekly"
					descriptionAbove={true}
				>
					<CategorySection
						name="Seniority"
						chartData={seniorityChartData}
						tableData={seniorityTableData}
						ranking={SeniorityRanking}
						maxMarketValue={maxMarketValue}
					/>
				</ChartSection>
			</div>

			<div ref={(el) => addChartRef(el, 3)}>
				<ChartSection
					title={"Opportunity Cost"}
					description={descriptions.marketValueAssessmentComparisonOppCost}
					updateFrequency="weekly"
					descriptionAbove={true}
				>
					<CategorySection
						name="Opportunity Cost"
						chartData={oppCostChartData}
						tableData={oppCostTableData}
						ranking={OppCostRanking}
						maxMarketValue={maxMarketValue}
					/>
				</ChartSection>
			</div>
		</>
	);
};

export default MarketValueAssessmentComparison;
