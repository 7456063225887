export const EUR = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "EUR",
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const capitalizeString = (string) => {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string;
	}
};

/**
 * Takes string of format "YYYY/YYYY" and returns "YY/YY"
 * @param {string} seasonName
 * @returns "YY/YY "string
 */

export const seasonNameShortener = (seasonName) => {
	const [startYear, endYear] = seasonName.split("/");

	if (startYear && endYear) {
		const formattedStartYear = startYear?.slice(-2);
		const formattedEndYear = endYear?.slice(-2);
		// return formatted value
		return `${formattedStartYear}/${formattedEndYear}`;
	} else {
		return seasonName;
	}
};

/**
 *  Round a number to at most one decimal, or show no decimals if it's an integer
 * @param {number} num
 * @returns formatted number
 */
export const maxOneDecimal = (num) => {
	if (num === null || isNaN(num)) return;
	return Number.isInteger(num) ? num : parseFloat(num.toFixed(1));
};
