import React, { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Edit, FlagRounded } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import CustomTable from "../Components/Table";
import profile from "../Logos/empty_profile.png";
import { profileStore } from "../State/ProfileStore";
import { maxOneDecimal } from "../Util/textFormatting.js";
import AcademyPlayerIcon from "./AcademyPlayerIcon.jsx";
import PlayerCardGrid from "./PlayerCardGrid.jsx";
import PlayerInfoModal from "./PlayerInfoModal.jsx";
import ReportOrEditPlayerModal from "./ReportOrEditPlayerModal";
import { squad } from "./squad.js";

const SquadContainer = observer(({ data, onEditSubmit }) => {
	const { user } = useAuth0();
	const theme = useTheme();
	const displayTable = useMediaQuery(theme.breakpoints.up("xl"));

	const [selectedPlayer, setSelectedPlayer] = useState(null);
	const [selectedEditPlayer, setSelectedEditPlayer] = useState(null);
	const [selectedReportPlayer, setSelectedReportPlayer] = useState(null);
	const [updateConfirmation, setUpdateConfirmation] = useState();

	const players = useMemo(
		() =>
			data.map((player) => ({
				ClubId: player.ClubId,
				ClubName: player.ClubName,
				PlayerId: player.PlayerId,
				Name: `${player.FirstName} ${player.LastName}`,
				FirstName: player.FirstName,
				LastName: player.LastName,
				DateOfBirth: player.DateOfBirth ? player.DateOfBirth : null,
				Foot: player.Foot,
				Height: player.Height,
				Weight: player.Weight,
				PositionId: player.PositionId,
				PositionName: player.PositionName,
				TransferKPI: player.TransferKPI,
				ContractExpiration: player.ContractExpiration ? player.ContractExpiration : null,
				BirthPlaceAreaId: player.BirthplaceAreaId,
				BirthPlaceArea: player.BirthplaceArea,
				PassPortAreaId: player.PassportAreaId,
				PassPortArea: player.PassportArea,
				ImageUrl: player.ImageUrl,
				AcademyClubIds: player.AcademyClubIds,
				AcademyClubNames: player.AcademyClubNames,
			})),
		[data]
	);

	const tableColumns = useMemo(() => {
		const columns = [
			{
				field: "ImageUrl",
				headerName: " ",
				customContent: (field, row) =>
					field ? (
						<Avatar alt={row.Name} src={field} />
					) : (
						<Avatar alt={"Empty profile image"} src={profile} />
					),
			},
			{
				field: "Name",
				headerName: "Name",
				headerInfo: (
					<span
						style={{
							display: "flex",
							alignItems: "center",
							gap: 5,
							whiteSpace: "nowrap",
						}}
					>
						<AcademyPlayerIcon /> = Academy player
					</span>
				),
				customContent: (field, row) => (
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<Button sx={{ whiteSpace: "nowrap" }} onClick={() => setSelectedPlayer(row)}>
							{field}
						</Button>

						{row.AcademyClubIds?.includes(row.ClubId) ? <AcademyPlayerIcon /> : null}
					</Box>
				),
			},
			{ field: "PositionName", headerName: "Position" },
			{ field: "Foot", headerName: "Foot" },
			{
				field: "DateOfBirth",
				headerName: "Date of Birth",
				customContent: (field, row) => dayjs(field).format("YYYY-MM-DD"),
			},
			{
				field: "TransferKPI",
				headerName: "Transfer KPI",
				customContent: (field) => maxOneDecimal(field),
				customStyle: { textAlign: "center" },
			},
			{ field: "BirthPlaceArea", headerName: "Birthplace" },
		];
		if (profileStore.isAdministrator()) {
			columns.push({
				field: "",
				headerName: "Edit",

				customContent: (field, row) => (
					<IconButton onClick={() => handleEditClick(row)}>
						<Edit sx={{ color: "primary.main" }} />
					</IconButton>
				),
				disableSort: true,
			});
		} else {
			columns.push({
				field: "",
				headerName: "Report error",
				headerInfo: "Press the flag icon to report an error in the data",
				customContent: (field, row) => (
					<IconButton onClick={() => handleReportClick(row)}>
						<FlagRounded sx={{ color: "primary.main" }} />
					</IconButton>
				),
				customStyle: { textAlign: "center" },
				disableSort: true,
			});
		}
		return columns;
	}, [user.email]);

	const handleReportClick = (player) => {
		setSelectedReportPlayer(player);
	};

	const handleEditClick = (player) => {
		setSelectedEditPlayer(player);
	};

	const handleEditSubmit = async (
		positionId,
		birthplaceAreaId,
		birthDate,
		contractExpiration,
		foot,
		height,
		weight,
		passportAreaId,
		lastname,
		firstname,
		academyClubIds
	) => {
		try {
			setUpdateConfirmation("");

			if (selectedEditPlayer) {
				await squad.updatePlayer(
					selectedEditPlayer.PlayerId,
					selectedEditPlayer.ClubId,
					firstname,
					lastname,
					birthDate.format("YYYY-MM-DDTHH:mm:ssZ"),
					birthplaceAreaId,
					passportAreaId,
					foot,
					height,
					weight,
					contractExpiration.format("YYYY-MM-DDTHH:mm:ssZ"),
					positionId,
					academyClubIds
				);
				setUpdateConfirmation("Successfully updated player!");
			}
		} catch (e) {
			setUpdateConfirmation(
				"Could not update player. Please try again or contact support."
			);
		} finally {
			setSelectedEditPlayer(null);
			onEditSubmit();
		}
	};

	const handleReportSubmit = async (
		positionId,
		birthplaceAreaId,
		birthDate,
		contractExpiration,
		foot,
		height,
		weight,
		passportAreaId,
		lastname,
		firstname,
		academyClubIds
	) => {
		try {
			setUpdateConfirmation("");

			if (selectedReportPlayer) {
				await squad.reportPlayerError(
					selectedReportPlayer.PlayerId,
					selectedReportPlayer.ClubId,
					firstname,
					lastname,
					birthDate.format("YYYY-MM-DDTHH:mm:ssZ"),
					birthplaceAreaId,
					passportAreaId,
					foot,
					height,
					weight,
					contractExpiration.format("YYYY-MM-DDTHH:mm:ssZ"),
					positionId,
					academyClubIds
				);
				setUpdateConfirmation(
					"Your report has been notified to the team. Thank you for your feedback!"
				);
			}
		} catch (e) {
			setUpdateConfirmation(
				"Could not send report. Please try again or contact support."
			);
		} finally {
			setSelectedReportPlayer(null);
			onEditSubmit();
		}
	};

	return (
		<>
			{displayTable ? (
				<CustomTable tableData={players} tableColumns={tableColumns} />
			) : (
				<PlayerCardGrid
					players={players}
					selectedPlayer={selectedPlayer}
					onSelectPlayer={(p) => setSelectedPlayer(p)}
					onSelectEditPlayer={(p) => setSelectedEditPlayer(p)}
					onSelectReportPlayer={(p) => setSelectedReportPlayer(p)}
				/>
			)}
			{selectedEditPlayer && (
				<ReportOrEditPlayerModal
					open={!!selectedEditPlayer}
					player={selectedEditPlayer}
					onSave={handleEditSubmit}
					onClose={() => setSelectedEditPlayer(null)}
					mode="Edit"
				/>
			)}
			{selectedReportPlayer && (
				<ReportOrEditPlayerModal
					open={!!selectedReportPlayer}
					player={selectedReportPlayer}
					onSave={handleReportSubmit}
					onClose={() => setSelectedReportPlayer(null)}
					mode="Report"
				/>
			)}
			<Dialog open={!!updateConfirmation}>
				<DialogContent>{updateConfirmation}</DialogContent>
				<DialogActions>
					<Button autoFocus variant="contained" onClick={() => setUpdateConfirmation()}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			{selectedPlayer && (
				<PlayerInfoModal
					player={selectedPlayer}
					onClose={() => setSelectedPlayer(null)}
				/>
			)}
		</>
	);
});

export default SquadContainer;
