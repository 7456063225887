import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PlayerCard from "./PlayerCard";

const gridStyle = {
	display: "grid",
	gridTemplateColumns: { xs: "1fr 1fr", sm: "1fr 1fr 1fr", lg: "1fr 1fr 1fr 1fr" },
	gap: { xs: 2, sm: 3 },
	pb: 2,
};

const PlayerCardGrid = ({
	players,
	selectedPlayer,
	onSelectPlayer,
	onSelectEditPlayer,
	onSelectReportPlayer,
}) => {
	const [sorting, setSorting] = useState("Name");

	const handleChange = (event) => {
		setSorting(event.target.value);
	};

	const sortedPlayers = useMemo(() => {
		switch (sorting) {
			case "Name":
			case "PositionName":
				return players.sort((a, b) => {
					// Sort ascending
					if (a[sorting] === null) return 1;
					if (b[sorting] === null) return -1;
					return a[sorting]?.localeCompare(b?.[sorting]);
				});
			case "DateOfBirth":
			case "ContractExpiration":
				// Sort descending
				return players.sort((a, b) => {
					if (a[sorting] === null) return 1;
					if (b[sorting] === null) return -1;
					return b[sorting]?.localeCompare(a?.[sorting]);
				});
			case "TransferKPI":
			case "Height":
			case "Weight":
				// Sort descending
				return players.sort((a, b) => b[sorting] - a[sorting]);
			default:
				return players;
		}
	}, [players, sorting]);

	return (
		<>
			<FormControl fullWidth>
				<InputLabel id="sort-select-label">Sort by</InputLabel>
				<Select
					labelId="sort-select-label"
					value={sorting}
					label="Sort by"
					onChange={handleChange}
				>
					<MenuItem value={"Name"}>Name</MenuItem>
					<MenuItem value={"PositionName"}>Position</MenuItem>
					<MenuItem value={"DateOfBirth"}>Date of birth</MenuItem>
					<MenuItem value={"TransferKPI"}>Transfer KPI</MenuItem>
					<MenuItem value={"ContractExpiration"}>Contract expiration</MenuItem>
					<MenuItem value={"Height"}>Height</MenuItem>
					<MenuItem value={"Weight"}>Weight</MenuItem>
				</Select>
			</FormControl>
			<Box sx={gridStyle}>
				{sortedPlayers.map((p) => (
					<PlayerCard
						key={p.PlayerId}
						player={p}
						isSelected={selectedPlayer?.PlayerId === p.PlayerId}
						onClick={() => onSelectPlayer(p)}
						onEditClick={() => onSelectEditPlayer(p)}
						onReportClick={() => onSelectReportPlayer(p)}
						highlightedValue={sorting}
					/>
				))}
			</Box>
		</>
	);
};

export default PlayerCardGrid;
